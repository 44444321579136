.wi-form-selectColor {
  border: none;
  width: 100%;
  li {
    float: left;
    background: transparent !important;
    .border-content-item{
      width: 38px;
      height: 38px;
      border: 2px solid transparent;
      border-radius: 50% !important;
      position: relative;
    }

    .content-item-color {
      width: 30px;
      height: 30px;
      border-radius:  50% !important;
      border: 3px solid rgb(41 35 169 / 20%);
      position: absolute;
      top: 50%;
      right: 50%;
      margin-top: -15px;
      margin-right: -15px;
    }
  }
  .p-listbox-item {
    padding: 0.5% !important;
  }
  .p-highlight {
    .border-content-item {
      border: 2px solid #0a708f !important;
      border-radius: 50% !important;
    }
    .content-text-color{
      color: #0a708f;
      font-weight: bold;
    }
    box-shadow: none !important;
  }
  li:visited,
  li:active {
    .border-content-item {
      border: 2px solid #0a708f !important;
      border-radius: 50% !important;
    }
    box-shadow: none !important;
  }
  li:hover {
    .border-content-item {
      border: 2px solid #71adbf !important;
      border-radius: 50% !important;
    }
  }
}