@use "../../../../utils/variable.scss" as variable;
@import "../../../../utils/style/style-common.scss";

.filter-wir-component {
    width: 100%;
    .wi-search-container {
        width: 192px;
        display: inline-block;

        .pi-search {
            font-size: 15px;
            // margin-top: 2px;
        }

        .p-input-icon-left {
            .p-inputtext {
                height: 30px!important;
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 13px!important;
            }
        }
    }

    .status-panel,
    .filter-panel {
        padding: 5px 0px;
    }

    .p-button {
        color: variable.$lavender-color;
        background-color: variable.$white;
        padding: 5px 7px;
        font-size: 11.5px;
        border: 0.5px dashed variable.$color-darkgray;
        border-radius: 20px;
        outline: 0;
        box-shadow: none;
        cursor: pointer;

        .p-button-icon-left {
            margin-right: 5px;
        }

        .p-button-label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 150px;
        }
    }

    .filter-container {
        text-align: left;
        flex: 1;
        margin: -5px;

        .wi-filter-option-wir.p-button {
            margin: 5px 2px!important;
        }

        .wi-filter-option-wir.p-button:focus,
        .wi-filter-option-wir.p-button.filter-active,
        .wi-filter-option-wir.p-button:hover {
            color: variable.$text-color-white;
            background-color: variable.$second-green-color;
            border: 0.5px solid variable.$second-green-color;

        }

        .wi-filter-option-wir.p-button.unvalid-date {
            color: variable.$text-color-white;
            background-color: variable.$red-error;
            border: 0.5px solid variable.$red-error;
        }

        .wi-filter-option-wir.p-button:nth-child(even) {
            margin: 0 5px;
        }

        .wi-filter-option-wir.p-button:nth-child(odd) {
            margin: 0rem;
        }
    }

    .remove-filter-button .p-button {
        border: none;
        .p-button-icon {
            font-size: 12px;
        }
    }

    .remove-filter-button .p-button:focus,
    .remove-filter-button .p-button:hover {
        color: variable.$text-color-gray;
        background-color: variable.$white;
    }

    .actions-right {
        display: flex;
        align-items: baseline;
        .actions-wir {
            .wi-menu-button-action {
                button {
                   background-color: variable.$primary-green-color;
                   color: variable.$white;
                   padding: 5px 15px;
                   border: solid 1px variable.$white;

                   &:hover {
                    background-color: variable.$second-yellow-color !important;
                    color: variable.$primary-green-color !important;
                    border-color: variable.$second-yellow-color !important;
                  }
                }
            }
        }
    }
}

.overlaypanel-filter-donations .p-overlaypanel-content {
    padding: 0 0.5rem;
    margin: 0;

    .p-checkbox-icon {
        font-size: 12px;
        font-weight: 700;
    }

    label {
        font-weight: 600;
        font-size: 14px;
        margin-left: 5px;
    }

    .p-listbox-list-wrapper {
        max-height: 600px!important;
    }

    .p-listbox.p-component {
       @extend .listbox-common;

        .p-listbox-list {
            .p-listbox-item {
                padding: 5px;
            }
        }

        .p-checkbox {
            @extend .checkbox-common;
        }
    }

    .p-calendar {
        padding: 0;
        width: 100%;
        .p-datepicker {
            border: 0;
            .p-datepicker-header {
                padding: 0.25rem;
                .p-datepicker-next .pi,
                .p-datepicker-prev .pi {
                    font-size: 14px;
                    box-shadow: none;
                }
                .p-datepicker-next:focus,
                .p-datepicker-prev:focus {
                    box-shadow: none;
                }
                .p-datepicker-title {
                    line-height: 0;
                    .p-datepicker-month,
                    .p-datepicker-year {
                        font-size: 14px;
                    }
                }
            }
            .p-datepicker-calendar-container .p-datepicker-calendar thead tr th,
            .p-datepicker-calendar-container .p-datepicker-calendar tbody tr td {
                padding: 0.25rem;
                font-size: 14px;
                text-align: center;
            }
        }
        .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
            box-shadow: none;
        }
        .p-datepicker table td > span.p-highlight {
            background-color: variable.$light-green-row;
            color: variable.$text-color-gray;
            box-shadow: none;
        }
        .p-datepicker table td > span:focus {
            box-shadow: none;
        }
    }

}

.overlay_panel_wir .p-overlaypanel-content .p-listbox.p-component .p-listbox-list .p-listbox-item:nth-child(11) {
    break-after: unset !important;
}