@use "../../utils/variable.scss" as variable;

.users-manager {
  .manager-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
