@use '../../../utils/variable.scss' as variable;
@import '../../../utils/style/style-common.scss';

.faq-manager {
    flex-direction: column;
    display: flex;
    position: relative;
    .manager-container {
      width: 100%;

      .headline {
        @extend .headline-common;
      }
      
      .certificate {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .certificate-action {
          width: 100%;
        }
      }

      .search-box {
        @extend.search-box-common;
      }

      .table-faq { 
        @extend .table-common;
        @extend .table-common-extra;
        @extend .footer-global-common;
        
        tr {
          

          .wi-html-content {
            height: 150px;
            overflow: hidden;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              left: 0px;
              right: 0px;
              height: 30%;
              bottom: 0px;
              background: linear-gradient(180deg, rgba(96, 96, 95, 0) 0%, rgba(255,255,255,1) 80%);
              pointer-events: none;
            }
          }
        } 
      }
    }
  }
  