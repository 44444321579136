@use "../../../utils/variable.scss" as variable;

.form-element {
    .p-accordion {
        // max-height: 500px;
        overflow-x: hidden;
  
       
    }

    .disable-btn {
        filter: grayscale(100%);
        cursor: not-allowed;
        pointer-events: none;
    }
     
    .p-tree.form-tree {
        border: none;
        padding: 0;

        .form-tree-content {
            padding: 0;

            .p-treenode-content {
                padding: 0;
                
                &:focus {
                    box-shadow: none;
                }

                &.p-treenode-dragover {
                    background: variable.$light-green-row;
                }
            }

            .p-treenode-droppoint.p-treenode-droppoint-active {
                background-color: variable.$pale-green;
            }

            .p-tree-toggler {
                display: none;
            }

            .p-panel.p-panel-toggleable {
                width: 100%;
                border-radius: 20px;
                overflow: hidden;

                .p-panel-content {
                    padding: 0;
                    padding-bottom: 10px;
                    border-bottom-right-radius: 20px; 
                    border-bottom-left-radius: 20px;
                }

                .p-panel-header {
                    border-radius: 20px;
                    padding: 5px 10px;
                    font-size: 14px;
                    background: variable.$light-green-row;
                    border: 1px solid variable.$light-green-row;
                    color: #7a848b;
                    display: flex;

                    .p-panel-header-icon {
                        width: 1.6rem;
                        height: 1.6rem;
                    }

                    .p-panel-title {
                        flex: 1;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.btn-submit-list {
    background-color: variable.$coupon-new;
    padding: 8px 10px;
    border-radius: 20px;
    margin-top: 10px;
    width: fit-content;
    cursor: pointer;
    border: none;
    text-align: center;
    background: variable.$light-green-row;
    border: 3px dashed variable.$second-green-color;
    // border: 1px solid #13735f;
    color: variable.$second-green-color;
    width: 100%;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }

    &:hover { 
        background-color: variable.$second-green-color !important;
        color: variable.$text-color-white;
        // border: 1px solid #13735f !important;

        border: 3px dashed variable.$second-green-color !important;
        box-shadow: none;
    }
    
    i {
        font-size: 23px;
        color: inherit;
        margin: auto;
    }
}

