@use '../../utils/variable.scss' as variable;
@import '../../utils/style/style-common.scss';

.wir-detail-page {
    .wir-detail-container {
        .wir-detail-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 0.5px solid variable.$label-color;

            .text {
                display: flex;
                .name {
                    padding-right: 1.5rem;
                    font-weight: 700;
                    font-family: variable.$font-name-Sora;
                    font-size: 24px;
                    color: variable.$primary-green-color;
                    margin: 0;
                }

                .actions {
                    @extend .wi-menu-button-action;
                }
            }
        }

        .partner-info {
            padding: 10px 0 0 0;
            border-bottom: 0.5px solid variable.$label-color;
            .partner-info-container {
                display: flex;

                .logo-wi-run {
                    height: 96px;
                    width: auto;
                    .logo-wi-run-img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                
                .headline {
                    text-align: left;
                    flex: 1;

                    .label {
                        font-weight: 700;
                        font-style: italic;
                        font-size: 1rem;
                        color: #333333;
                        padding-bottom: 0.5rem;
                    }

                    .partner-content {
                        color: variable.$primary-green-color;

                        .text {
                            font-weight: 700;
                            font-family: variable.$font-name-Sora;
                            font-size: 20px;
                            margin: 0;
                        }

                        .info {
                            display: flex;
                            font-size: 14px;
                            font-weight: 700;
                            .name {
                                padding-right: 1rem;
                            }

                            .email {
                                font-style: italic;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }   
        }

        .wir-info {
            padding: 10px 0 0 0;
            border-bottom: 0.5px solid variable.$label-color;
            .wir-info-container {
                .headline {
                    text-align: left;
                    .label {
                        font-weight: 700;
                        font-style: italic;
                        font-size: 1rem;
                        color: #333333;
                        padding-bottom: 1rem;
                    }
                }

                .content {
                    .content-row {
                            font-weight: 700;
                            font-size: 14px;
                            text-align: left;

                        .label {
                            font-style: italic;
                            color: #737373;
                        }

                        .text {
                            color: #333333;
                            margin-bottom: 13px;

                            a {
                                color: #13735f;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }

        .teams-info {
            padding: 10px 0 0 0;
            .teams-info-container {
                .headline {
                    text-align: left;
                    .label {
                        font-weight: 700;
                        font-style: italic;
                        font-size: 1rem;
                        color: #333333;
                        margin-bottom: 10px;
                    }

                    .wir-header-panel {
                        .p-inputtext {
                            height: 30px!important;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            font-size: 13px!important;
                        }

                        .pi-search {
                            font-size: 12px;
                            padding-top: 4px;
                        }
                    }
                }

                .teams-table {
                    @extend .table-common;
                            @extend .table-common-extra;
                            .p-datatable-table {
                                font-size: 14px;
                                line-height: normal;
                             
                            }
                
                            .p-datatable .p-datatable-wrapper {
                                height: unset !important;
                            }

                            .p-datatable-table .p-datatable-thead tr th {
                                font-size: 14px;
                              }
                
                            .p-datatable .p-datatable-wrapper .p-datatable-table tr {
                                background-color: variable.$white !important;
                                border-bottom: none!important;
                        
                                td {
                                    font-size: 14px;
                                    border-bottom: #ebeef1 1px solid;
                                  &:last-child {
                                    background-color: variable.$white !important;
                                  }
                                }
                              }
                        
                              .p-datatable .p-datatable-wrapper .p-datatable-table tr:hover {
                        
                                td {
                                    background-color: variable.$primary-gray !important;
                                }
                              }
                
                              .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
                                background-color: variable.$light-green-row !important;
                                color: variable.$second-green-color !important;
                              }
                }
            }
        }
    }
}