/* latin-ext */
@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Medium.ttf) format("truetype");
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-SemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}
