@use "../../utils/variable.scss" as variable;

.navbar-horizontal-wilderness.bg-primary-wilderness {
}

.navbar-vertical-wilderness.bg-primary-wilderness {
    background: variable.$second-green-color;;
}

.navbar-horizontal-wilderness {
    max-width: 1690px;
    margin: auto;
    padding: 0.5rem 30px;
    width: 100%;
    border-radius: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.navbar-horizontal-wilderness > .p-menubar-start {
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .wilderness-logo {
        display: flex;
        height: 38px;
        padding: 0;
        text-decoration: none;
        font-family: variable.$font-name-Sora;
        cursor: pointer;

        .wilderness-logo {
            height: 38px;
            padding: 0;
        }

        .wi-logo-text {
            font-size: 11px;
            text-align: left;
            color: variable.$white;
            line-height: 1.2;
            font-weight: 600;
            vertical-align: bottom;
            padding-top: 15px;
        }
    }

    > .wi-global-search-container {
        width: calc(100% - 170px);

        .p-component.p-inputtext {
            width: 600px;
            height: 38px;
            border-radius: 20px;
            border: none;
            box-shadow: none;
            font-size: 13px;
        }
    }

    > .wi-help-container {
        width: 100px;
        .help-link {
            text-decoration: none;
            width: 70px;
            font-size: 14px;
            color: variable.$white;

            i {
                font-size: 15px;
            }

            &:hover {
                color: variable.$light-grey-color;
            }
        }
    }

    > .wi-user-info-container {
        width: 38px;
        height: 38px;
        text-align: right;

        > .p-button.p-component {
            background-color: variable.$bg-user-info;
            padding: 8px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            box-shadow: none;
            border: 0;
            outline: none;
            cursor: pointer;

            > .p-button-label {
                color: variable.$text-color-white;
                font-size: 14px;
                font-weight: 700;
            } 
        }
    }
}

.navbar-horizontal-wilderness > .p-menu.wi-menu {
    padding: 0;
    background: variable.$transparent;
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal {
    width: 100%;
    border: none;
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem > .p-menuitem-link {
    padding: 5px 10px;
    border-radius: 20px;

    &:focus {
        box-shadow: none;
    }
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem > .p-menuitem-link:hover,
.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: variable.$light-green-row;
    border-radius: 20px;
    box-shadow: none;

    > .p-menuitem-icon,
    > .p-menuitem-text {
        color: variable.$second-green-color;
    }
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem > .p-menuitem-link > .p-menuitem-icon,
.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem > .p-menuitem-link > .p-menuitem-text {
    color: variable.$text-color-white;
    font-size: 13px;
    font-weight: 700;
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem.wi-menu-item-active {
    background: variable.$text-color-white;
    border-radius: 20px;
    
    > .p-menuitem-link > .p-menuitem-icon,
    > .p-menuitem-link > .p-menuitem-text {
      color: variable.$second-green-color;
    }

    > .p-menuitem-link > .p-menuitem-text {
      font-weight: 700;
    }
}

.navbar-horizontal-wilderness > .p-menu.wi-menu.horizontal > .p-menu-list > .wi-menu-item.p-menuitem {
    margin: 7px 2px 0px 2px;
}

.navbar-vertical-wilderness {
    &.leaf-mode {
        background: variable.$second-green-color;
    }

    .navbar-logo {
        padding: 20px 60px 0 60px;
        padding-bottom: 0;
        width: 100%;
        height: 75px;
        overflow: hidden;
    }

    .p-menu {
        padding-top: 20px;
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 0 !important;
        background: variable.$second-green-color;

        .wi-menu-item {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            margin-left: 10px;

            .p-menuitem-icon {
                margin-right: 20px;
                min-width: 25px;
            }

            .p-menuitem-icon,
            .p-menuitem-text {
                color: variable.$text-color-white;
                font-size: 14px;
            }

            &:hover,
            .p-menuitem-link:not(.p-disabled):hover {
                background: variable.$light-green-row;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;

                .p-menuitem-icon,
                .p-menuitem-text {
                    color: variable.$second-green-color;
                }
          }
        }

        .wi-menu-item-active {
          background: variable.$second-green-color;

          .p-menuitem-icon {
            color: variable.$text-color-white;
          }

          .p-menuitem-text {
            color: variable.$text-color-white;
            font-weight: 700;
          }
        }
    }
}

@media only screen and (max-width: 766.99px) {
    .navbar-horizontal-wilderness > .p-menubar-start > .wi-global-search-container {
        .pi-search,
        .p-component.p-inputtext {
            display: none;
        }
    }
}