@use "../../../../utils/variable.scss" as variable;
@import "../../../../utils/style/style-common.scss";

.update-team-form {
    width: 100%;
    height: 100%;
    .update-team-form-container {

        @extend .form-content;

        .row-content {

            .global-page-item {
                display: flex;
            
                .global-page-picture {
                  height: 70px;
                  width: 100px;
                  min-width: 100px;
                  border-radius: 20px;
                  background: variable.$light-slate-gray;
            
                  > img {
                    height: 70px;
                    width: 100px;
                    border-radius: 20px;
                    background: variable.$light-slate-gray;
                    object-fit: cover;
                    object-position: center;
                  }
                }
            
                .page-details {
                  padding: 0 10px;
        
                  .global-page-name {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 14px;
                    text-overflow: ellipsis;
                    width: 300px;
                    white-space: nowrap;
                    overflow: hidden !important;
                  }
            
                  .global-page-position {
                    color: variable.$light-slate-gray;
                    font-size: 12px;
                    width: 300px;
                  }
                }
              }

            .btn-submit {
                display: flex;
                justify-content: flex-end;
                button {
                    border-color: transparent;
                    background-color: variable.$primary-green-color !important;
                    color: variable.$second-yellow-color !important;
                    margin-left: 1rem;
                    padding: 0.5rem 1.5rem;
                    min-height: 2rem;
                    height: 2rem;
                    font-family: variable.$font-name-Sora;
                    font-size: 14px;

                    &:hover {
                        background-color: variable.$second-yellow-color !important;
                        color: variable.$primary-green-color !important;
                        border-color: variable.$second-yellow-color !important;
                    }
                }
            }

            input, textarea {
                color: variable.$primary-green-color !important;
            }

            label {
                font-size: 14px !important;
                text-transform: unset !important;

                .required-label {
                    color: variable.$red-error;
                }

                .lowercase-label {
                    text-transform: lowercase;
                }
            }

            .p-selectbutton {
                width: 100%;
                .p-button {
                    width: 50%;
                }
            }

            .row {

                .error-existing {
                    display: flex;
                    align-items: center;
                }

            .calendar-item {
                position: relative;
                .p-calendar {
                    input {
                        border-radius: 1rem;
                        color: variable.$primary-green-color;
                        z-index: 2;
                        &:focus {
                            border-right: unset !important;
                        }
                    }
                }

                .p-button.p-datepicker-trigger {
                    height: 40px;
                    border: none;
                    width: 20px;
                    position: absolute;
                    right: 1rem;
                    z-index: 2;
                    top: 1px;
                    color: variable.$primary-green-color;

                    &:focus {
                        border: none;
                    }
                }
            }

            .check-box-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                label {
                    color: variable.$light-slate-gray;
                    font-size: 14px;
                    font-style: italic;

                    svg {
                        width: 20px !important;
                    }
                }
            }

            .error-existing-donor {
                @extend .error;

                padding: unset;
                &::before {
                    display: none;
                }
            }
                .update-team-submit-component {
                    display: flex;
                    justify-content: flex-end;
                    // padding-top: 1rem;
                    
                    button {
                        border-color: transparent;
                        background-color: variable.$primary-green-color !important;
                        color: variable.$second-yellow-color !important;
                        margin-left: 1rem;
                        padding: 0.5rem 1.5rem;
                        min-height: 2rem;
                        height: 2rem;
                        font-family: variable.$font-name-Sora;
                        font-size: 14px;
    
                        &:hover {
                            background-color: variable.$second-yellow-color !important;
                            color: variable.$primary-green-color !important;
                            border-color: variable.$second-yellow-color !important;
                        }
                    }
                }
            }
        }
    }
}