.wi-dialog-mask-v2 {
  *:not(.pi):not(.fa-solid) {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: variable.$text-default-size;
  }

  .p-dialog {
    border-radius: 24px;
    overflow: hidden;
    
    .p-dialog-header {
      padding: 16px 32px;
      display: none;
    }

    .p-dialog-content {
      padding: 24px;
    }
  }

  .h1 {
    font-size: variable.$text-h1-size;
  }
}

.top-mask-sidebar.p-sidebar-mask.p-component-overlay {
  z-index: 6000!important;
}