@use "../../../../../utils/variable.scss" as variable;
@import '../../../../../utils/style/style-common.scss';

.person-create-form {
  .person-form-container {
    @extend .form-content;

    .btn-submit-container {
      .btn-submit {
        background-color: transparent;
        border: 1px solid variable.$white;

        &:hover {
          border: 1px solid variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }
    }

    .wi-form-toggle-button-partner.p-inputswitch {
      &.p-inputswitch-checked, &.p-inputswitch-checked:hover {
          .p-inputswitch-slider {
              background:  variable.$second-green-color;
          }
      }
      margin-top: 5px;
    }

    .label-upload-field {
      &.partner_logo {
        padding: 0;
        margin: 0;
        width: 50% !important;
        height: 126px !important;
        min-height: 126px !important;
        margin-left: 0 !important;

        .image-cert {
          width: 100%;
          height: 124px;
          object-fit: cover;
          object-position: 50% 50%;
          border-radius: 8px;
        }
      }
      .image-cert.media-preview {
        object-fit: cover !important;
      }

      &.partner-mobile-picture {
        margin: auto;
      }
    }

    .profile-picture-people{
      margin-top: 0px;
      height: calc(9vmax + 50px);
      width: calc(9vmax + 50px);
      max-height: 210px;
      max-width: 210px;
      .label{
        margin-right: 10px !important; 
      }
    }

    .tox-tinymce{
      margin-bottom: -15px;
    }

    .upload-logo-row .element-form {
      padding: 10px 0px;

      .label-upload-field {
        padding: 0;
        margin: 0 auto;
      }
    }

    .upload-logo-row,
    .textarea-container {
      .element-form {
        label {
          display: block;
          font-size: 12px;
          text-transform: uppercase;
          color: variable.$light-slate-gray;
          padding-bottom: 4px;
          position: relative;
          padding-right: 8px;
          font-style: italic;

          .required-label {
            color: variable.$red-error;
          }
        }
      }
    }
    .p-dropdown{
      margin-top: 0px;  
    }
    .search-global .search-form-element input {
      height: 42px;
    }
  }
}