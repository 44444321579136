@use "../../utils/variable.scss" as variable;

$login-logo-height: 53px;
$login-logo-height2: -53px;
$login-logo-mb: 56px;

.login-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center; /* align item horizontally */
  align-items: center; /* align item vertically */

  .background-cover, .background-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }

  .background-backdrop {
    background: rgba(variable.$black, 50%);
  }

  .login-form-wrapper {
    margin: 0 auto;
    z-index: 2;
    margin-top: calc($login-logo-height2 - $login-logo-mb);

    .login-logo {
      object-fit: contain;
      object-position: center;
      height: $login-logo-height;
      margin-bottom: $login-logo-mb;
    }

    .login-form {
      border-radius: 48px;
      padding: 48px;
      background: variable.$white;
      display: flex;
      flex-direction: column;
      max-width: 520px;

      .note { 
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        text-align: left;
        
        &.color-gray {
          color: variable.$color-time;
        }

        b {
          color: variable.$primary-color;
          font-family: "Poppins", sans-serif;
        }
      }

      .login-title {
        width: 336px;
        font-family: "Poppins", sans-serif;
      }

      .row-common .element-form .label {
        font-weight: 400;
      }

      .wi-primary-button-v2 {
        font-weight: 500;
      }

      .login-error {
        display: flex;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: variable.$color-error;
      }

      .forgot-pass-wrapper {
        display: flex;
        justify-content: center;

        > * {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          color: variable.$icon-disabled;
          font-size: variable.$text-mini-size;
        }

        a {
          text-decoration: none;
          color: variable.$black;
        }
      }

      .check-circle-icon {
        width: 24px;
        position: relative;
        height: 24px;
        overflow: hidden;
        flex-shrink: 0;
      }

      .label-text {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;

        path {
          stroke: variable.$black;
        }

        &.validation-pass {
          color: variable.$color-light-green;

          path {
            stroke: variable.$color-light-green;
          }
        }
      }

      .no-space {
        position: relative;
        line-height: 24px;
        font-family: "Poppins", sans-serif;
      }
      
      .label-text-parent {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        text-align: left;
        color: variable.$black;
        font-family: Poppins;
      }
    }
  }
}
