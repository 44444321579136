@use "../../../utils/variable.scss" as variable;
@import "../../../utils/style/style-common.scss";

.download-certificate-form {
  .certificate-form-container {

    .language-container {
      .wi-selectbutton.p-selectbutton {
        width: 100%;
        height: 48px;

        .p-button {
          width: 50%;
          height: 40px;
        }
      }
    }
  }
}

