@use "../../utils/variable.scss" as variable;

.card.page-wrapper {
  // margin: -20px -30px 0px 0px;
  margin: 0px;
  border: none;
  background: variable.$second-green-color;

  .page-form {
    padding: 15px;
  }

  .page-wrapper-fullscreen {
    .p-tabview-nav-container {
      display: none;
    }
  }
}

.loading-component {
  position: absolute;
  width: calc(100% - 60px);
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: variable.$background-color-white;
  opacity: 0.6;
  z-index: 9999;
  .p-progress-spinner-circle {
      stroke: variable.$second-green-color !important;
  }

  @keyframes p-progress-spinner-color {
      0% { stroke: variable.$second-green-color !important; }
      100% { stroke: variable.$second-green-color !important; }
      40% { stroke: variable.$second-green-color !important; }
      66% { stroke: variable.$second-green-color !important; }
      80% { stroke: variable.$second-green-color !important; }
      90% { stroke: variable.$second-green-color !important; }
      }
}

.page-builder-header {
  margin: 0!important;
  height: 55px;
  overflow: hidden;

  &.page-builder-header-closed {
    height: 0;
  }

  .header-wrapper {
    background: variable.$primary-green-color;
    display: flex;
    padding: 0;
    height: 100%;

    .screen-mode {
      display: flex;
      padding: 10px;
      // border-bottom: 1px solid gray;
    }

    .mode-item {
      padding: 8px;
      font-size: 1.2rem;
      border-radius: 5px;
      // background: rgb(19, 115, 95);
      color: variable.$text-color-white;
      margin-left: 5px;
      cursor: pointer;

      &.zoom-btn {
        color: variable.$text-color-white;
        border-radius: 50%;
        /* color: rgb(19, 115, 95); */
        padding: 8px;
        font-size: 1.2rem;
        font-weight: 700;
      }

      &.disable-icon {
        background: transparent;
        cursor: not-allowed;
        color: variable.$gray-color;

        &:hover, &.mode-active {
          background: transparent;
          color: variable.$gray-color;
        }
      }

      &:hover, &.mode-active {
        // background: variable.$text-color-white;
        // border: 1px solid #b2b2b2;
        color: variable.$second-yellow-color;
      }
    }

    .btn-panel {
      padding: 10px;
      display: flex;

      .lang-item {
        padding: 2px 6px 6px;
        border-radius: 4px;
        margin-left: 5px;
        cursor: pointer;
        filter: grayscale(1);

        &.mode-active {
          // border: 1px solid #b2b2b2;
          // background: variable.$text-color-white;
          filter: grayscale(0);
        }
        .icon-lang {
          width: 30px;
          border-radius: 3px;
        }

        &:not(.mode-active) {
          filter: grayscale(100%);
        }
      }
    }

    .tab-menu {
      padding: 10px;
      // min-width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-left: 1px solid variable.$gray-color;

      .p-button {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        min-width: 80px;
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
        border: 0;        

        &:hover,
        &.p-highlight {
          background-color: variable.$background-color-white !important;
          color: variable.$second-green-color !important;
        }

        i {
          padding: 0;
          margin: 0;
        }
      }
    }

    .bg-panel {
      height: 50px;
      .bg-header {
        width: 350px;
        margin-top: -125px;
        opacity: 0.5;
      }
    }
  }
}

.builder-page {
  width: 100%;
  margin: 0!important;

  .list-components {
    width: 0!important;
    // flex: 1;
    // height: calc(100vh - 105px); old
    height: calc(100vh - 105px);
    text-align: left;
    border: 1px solid variable.$background-color-light-steel-blue;
    overflow-y: hidden;
    display: flex;
    transition: 1.16s ease-in-out all;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    &.list-components-closed {
      width: 0!important;
      transition: 1.16s ease-in-out all;
    }
  }

  .builder-components {
    width: calc(40%)!important;
    max-width: 700px !important;
    // flex: 1;
    // height: calc(100vh - 105px);
    height: calc(100vh - 200px);
    text-align: left;
    border: 1px solid variable.$background-color-light-steel-blue;
    overflow-y: hidden;
    display: flex;
    transition: 1.16s ease-in-out all;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    &.builder-components-closed {
      width: 0!important;
      transition: 1.16s ease-in-out all;
    }
    
    .builder-tab-view {
        flex: 1;
        width: 100%;

        .p-tabview-nav-container {
          display: none;
        }
    
        .p-tabview-panels {
          height: 100%;
          padding: 0;
          margin: 0;
          border: 0;
          border-radius: 0;

          .p-tabview-panel {
            height: 100%;
          }
        }

        .editor {
          display: flex;
          flex-direction: column;
          align-items: center;
          // padding: 15px;
          overflow: auto;
          overflow-x: hidden;
          height: 100%;

          .label-upload {
            width: 100%;
          }

          .p-accordion {
            .p-accordion-toggle-icon {
              font-size: 13px;
            }

            .p-accordion-header-link {
              padding: 5px 10px;;
              font-size: 13px;
              min-height: 40px;
            }

            .p-accordion-content {
              padding: 5px;
            }
          }
          
          .btn-submit {
            &.p-button {
                border-color: variable.$second-green-color;
                background: variable.$second-green-color;
                border-radius: 20px;
                font-size: 13px;
                font-weight: 700;
                padding: 10px 30px;

                &:focus {
                    box-shadow: none;
                }
        
                &:hover {
                    background-color: variable.$second-yellow-color;
                    border-color: variable.$second-yellow-color;
                    color: variable.$second-green-color;
                }
            }
          }
        }
      }
    .accordion-custom {
        .p-accordion-tab {
          padding: 0;
          margin: 0;
          width: 100%;
          .p-accordion-header {
            padding: 0;
            margin: 0;
    
            a {
              height: 40px;
              color: variable.$lavender-color;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              border: 0;
              border-radius: 0;

              &:hover {
                background-color: variable.$light-green-row;
              }

              &:focus {
                box-shadow: none !important;
              }
            }
          }
    
          .p-toggleable-content {
            height: calc(90vh - 40px);
            overflow-y: auto;

            .p-accordion-content {
              border: 0;
            }
          }

          &.p-accordion-tab-active {
            .p-accordion-header a {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              color: variable.$text-color-white;
              background-color: variable.$second-green-color;
            }
          }
        }

        .p-accordion-content {
          padding: 0;
        }
    }

    .component-editor,
    .components {
      width: 100%;
      overflow-y: hidden;

      p {
        width: 100%;
        border: 1px solid variable.$red-color;
      }
    }

    .components {
      .cta-blocks-wrapper {
        overflow-y: auto;
      }

      section {
        padding: 15px;
        margin: 1rem auto;
        border: 0.4rem dashed variable.$second-green-color;
        border-radius: 10px;
        zoom: 0.33;
        cursor: pointer;
      }
    }

    .component-editor {
      border-top: 5px solid variable.$background-color-light-steel-blue;
    }

  }
  
  .builder-content {
    // width: calc(100% - 250px)!important;
    overflow: hidden;
    flex: 1;
    min-width: 600px;
    text-align: center;
    background: variable.$background-color-white-smoke;
    position: relative;

    &.builder-content-closed {
      .droppable-component {
        min-height: 0px;
        height: 0;
        border: none;
        box-shadow: none;
      }

      .screen-desktop {
          width: 100%!important;
      }
    }

    &.builder-content-on-dragging {
      .droppable-component {
        height: 100%;
      }
    }
  }

  label.title-components,
  label.title-editor {
    padding: 0;
    text-transform: capitalize;
    margin: 1rem 1.5rem;
    color: variable.$default-color-text;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
  }
}

.wi-search-container{
  background: variable.$text-color-white;
  padding: 5px
}
.block-category{
  .block-category-title{
    i {
      border-style: solid;
      border-width: 1px;
      border-color: variable.$lavender-color;
      padding: 10px;
      width: 100%;
      height: 45px;
      background: variable.$second-green-color;
      color: variable.$text-color-white;
      font-size: 18px;
      justify-content: space-between;
      cursor: pointer;
      label{
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .block-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // grid-template-columns: auto auto;
    padding: 5px;

    .draggable-component {
      &:not(.empty-draggable) {
        cursor: move;
      }
    }

    .empty-draggable {
      border: none !important;
      height: 0;
    }
  }
}

.tabview-demo .tabview-header-icon i, .tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
    margin: 0 .5rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.p-tabview {

  .p-tabview-panels {
    padding: 0px;
  }

  .p-tabview-nav li  {
    .p-tabview-nav-link {
      padding: 10px;
      font-size: 15px;
    }
  }
}
.p-tabview .p-tabview-nav { 
  li.p-highlight .p-tabview-nav-link {
    background: variable.$background-color-white;
    border-color: variable.$second-green-color;
    color: variable.$second-green-color;
    border-bottom-width: 3px;
    background: variable.$white-green;
  }

  li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    // box-shadow: inset 0 0 0 0.2rem #8ACBC1;
    box-shadow: none;
  }

  .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: variable.$second-green-color;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  }
}

.editor-view {
  display: flex;
  height: 100%;
  // background: #f7f7f7;

  // .form-element {
  //   > * {
  //     background: #f7f7f7;
  //   }
  // }

  .tree-view {
    width: 230px;
    overflow-y: auto;
    min-width: 230px;
  }

  .editor-wrapper {
    flex: 1;
  }
}

.p-dialog {
  .layers-header {
    padding: 10px;
    text-align: center;
    background: variable.$second-green-color;
    color: variable.$text-color-white;
  }
  
  .layers-content {
    padding: 0px 0px!important;
  }
}