@use "../../../../utils/variable.scss" as variable;
.dialog-container {

  .language-container {
    .wi-selectbutton.p-selectbutton {
      width: 100%;
      height: 48px;
      
      .p-button {
        width: 50%;
      }
    }
  }
}
