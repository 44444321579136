@use "../../../utils/variable.scss" as variable;



.warning-dialog-v2 {
  border-radius: 44px!important;
  overflow: hidden;

  .p-dialog-header {
    display: none;
  }

  &.info-dialog {
    .p-dialog-content {
      .confirmation-content {
        i {
          background-color: variable.$second-green-color!important;
        }
      }
    }

    .p-dialog-footer {
      .button-confirm {
        background-color: variable.$second-green-color!important;
        border: 1px solid variable.$second-green-color!important;

        &:hover {
          color: variable.$second-green-color!important;
          background-color: transparent!important;
        }
      }
    }
  }

  .p-dialog-content {
    align-items: flex-start!important;
    padding: 24px 24px 16px 24px!important;

    .confirmation-content {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center !important;
      line-height: 1.3;
      font-size: variable.$text-default-size;
      margin: 0;
      
      *:not(.pi):not(.fa-solid) {
        font-family: "Poppins", sans-serif!important;
      }
    }
  }

  .p-dialog-footer {
    display: flex;
    padding: 0px 24px 24px 24px!important;
    justify-content: center;
    gap: 24px;

    .p-button {
      font-size: variable.$text-default-size;
      margin: 0;
    }
  }
}