@use "../../../utils/variable.scss" as variable;
@import "../../../utils/style/style-common.scss";

.media-manager-container-dialog-v2 {

  .active {
      background-color: variable.$dark-green !important;

      .file-panel-backdrop {
        display: flex;
      }
  }

  .file-panel-backdrop {
    position: absolute;
    z-index: 5;
    background: variable.$black;
    opacity: 50%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: variable.$white;
  }
}