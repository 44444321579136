@use "../../../utils/variable.scss" as variable;

.dropzone {
  padding: 35px 0 30px 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px dashed variable.$dark-green;
  background-color: variable.$dark-green;
  cursor: pointer;

  label {
    font-size: 16px;
  }

   &.upload-error {
    border: 2px dashed variable.$red-color;
    background-color: variable.$dark-red;
  }

  &.active {
    border-color: variable.$second-green-color;
    background-color: variable.$dark-green;
  }
  .pi {
    font-size: 2rem;
    opacity: 0.5;
    color:variable.$second-green-color;
    }
}

.preview-file-container {
  overflow-y: scroll;
  max-height: calc(80vh - 400px);
}

.file-review {
  margin-top: 10px;
  max-width: 100%;

  p {
    font-weight: 500;
  }

  .file-review-item {
    position: relative;
    display: flex;
    margin-bottom: 5px;
    background-color: var(--input-bg);
    padding: 5px 0px;
    border-radius: 20px;
    width: 100%;
    overflow: hidden

    // &:hover .file-preview-item-del {
    //   opacity: 1;
    // }
  }
  .file-review-img {
    width: 20%;
    margin-right: 20px;
    height: 100px;
    width: 150px;
    min-width: 150px;
    object-fit: cover;
    object-position: center;

    &.file-review-img-w-20{
      object-fit: scale-down;
    }

    &.file-review-img-w-40{
      width: 50%;
    }
    &.file-review-img-bg-svg, 
    &.file-review-img-bg-png, 
    &.file-review-img-bg-jpg, 
    &.file-review-img-bg-jpeg {
      background: variable.$gainsboro;
      width: 150px;
      min-width: 150px;
    }
  }

  .file-review-item-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    .file-review-item-info-name {
      font-size: 15px;
      font-weight: 700;
      margin: 0px;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .file-review-item-info-size {
      font-size: 13px;
      font-weight: 700;
      color: variable.$gray-color;
    }
  }
  .file-preview-item-del {
    background-color: variable.$red-error;
    // min-width: 30px;
    // min-height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: variable.$text-color-white;
    padding: 10px;
    margin: auto;

    i {
      min-width: 26px;
      min-height: 26px;
      line-height: 26px;
      text-align: center;
      font-size: 20px;
    }
    // position: absolute;
    // right: 20%;
    // top: 50%;
    // transform: translateY(-50%);
    // box-shadow: var(--box-shadow);
    // opacity: 0;
    // transition: opacity 0.3s ease;
    // box-sizing: border-box;
    // border: 1px solid;
  }  
}
