@use "../../../utils/variable.scss" as variable;

.wi-menu-button-action {
  .menu-button {
    height: 30px;
    font-size: 13px;
    border-radius: 20px;
    background: transparent;
    border: solid 1px white;
    box-shadow: none;
    padding: 5px 15px;

    .p-button-label {
      font-family: Sora;
    }

    &:enabled:hover {
      border-color: variable.$second-yellow-color;
      color: variable.$second-green-color;
      background: variable.$second-yellow-color;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .fa-ellipsis {
    border-radius: 50px;
    padding: 7px 8px;
    font-size: 13px;

    &:hover {
      background: variable.$color-darkgray;
    }
  }
}

.wi-button-action-menu {

  &.p-menu {
    min-width: 230px;
    z-index: 2206!important;
    width: auto;
    
    .p-menuitem-link {
      padding: 10px 20px;
      box-shadow: none;
      color: variable.$second-green-color;
      
      &:focus {
        box-shadow: none;
      }
      
      &:hover {
        box-shadow: none;
      }

      .p-menuitem-text {
        font-size: 13px;
        margin-left: 5px;
        color: variable.$second-green-color;
        font-weight: 700;
      }

      .p-menuitem-icon {
        color: variable.$second-green-color;
      }
    }
  }
}
