@use '../../../../utils/variable.scss'as variable;

.action-form-user {
    border-bottom: 1px solid variable.$background-color-sidebar;
}
  // .submit-btn {
  //     background-color: variable.$white;
  //     display: flex;
  //     justify-content: center;
  //     .submit-btn-container {
  //         // background-color: variable.$background-header;
  //         border-radius: 36px;
  //         display: flex;
  //         justify-content: space-between;
  //         flex: 1;
  //         gap: 16px;

  //         .wi-language-icon {
  //             width: 20px;
  //             height: 20px;
  //             display: flex;
              
  //             img {
  //                 width: 100%;
  //                 height: 100%;
  //             }
  //         }

  //         .p-dropdown-trigger-icon {
  //             font-size: 14px;
  //         }

  //         .wi-language {
  //             background-color: variable.$white;
  //             display: flex;
  //             justify-content: space-between;
  //             align-items: center;
  //             border-radius: 20px;
  //             height: variable.$primary-element-height;
  //         }

  //         .wi-dropdown-v2 {
  //             width: 100%;
  //             border-radius: 20px;
  //             border: none;

  //             &:focus-visible, &:hover, &:focus-within {
  //                 box-shadow: none;
  //                 outline: none;
  //                 border: none !important;
  //             }

  //             &.wi-status-active {
  //               .wi-language-label, .p-dropdown-trigger-icon {
  //                 color: variable.$color-light-green;
  //               }
  //             }
          
  //             &.wi-status-archived {
  //               .wi-language-label, .p-dropdown-trigger-icon {
  //                 color: variable.$color-gray;
  //               }
  //             }
          
  //             &.wi-status-new {
  //               .wi-language-label, .p-dropdown-trigger-icon {
  //                 color: variable.$color-light-blue;
  //               }
  //             }
          
  //             &.wi-status-inactive {
  //               .wi-language-label, .p-dropdown-trigger-icon {
  //                 color: variable.$color-orange;
  //               }
  //             }

  //             .p-dropdown-label {
  //                 padding: 10px 0 10px 10px;
  //                 display: flex;
  //                 align-items: center;
  //                 height: variable.$primary-element-height;

  //                 .wi-language-label {
  //                     width: 70px;
  //                     min-width: 70px;
  //                     height: 20px;
  //                 }

  //                 .wi-language-item {
  //                     display: flex;
  //                     justify-content: space-between;
  //                     align-items: center;
  //                 }
  //             }
  //         }
  //     }
  // }