@use "../../../../utils/variable.scss" as variable;

.donation-manager .header-content {
    .actions-donations {
        .wi-menu-button-action {
            button {
               background-color: variable.$primary-color;
               color: variable.$white;
               padding: 5px 25px;
               border: solid 1px variable.$white;
               height: 40px !important;
               font-family: "Poppins", sans-serif !important;
            }
        }
    }
}