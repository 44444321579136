@use '../../../utils/variable.scss' as variable;
@import '../../../utils/style/style-common.scss';

.donation-example-manager {
    flex-direction: column;
    display: flex;
    position: relative;
    .manager-container {
      width: 100%;

      .headline {
        @extend .headline-common;
      }
      
      .certificate {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .certificate-action {
          width: 100%;
        }
      }

      .search-box {
        @extend.search-box-common;
      }

      .table-donation-example {
        @extend .table-common;
        @extend .table-common-extra;
        @extend .footer-global-common;
      }
    }
  }
  