@use "../../../utils/variable.scss" as variable;

.wi-paginator-container {
  .p-paginator-pages button {
    border: 0;
    box-shadow: none;
    outline: 0;

    &.p-highlight {
      background-color: variable.$light-green-row !important;
      color: variable.$second-green-color !important;
    }    
  }
  .p-paginator-next:focus, 
  .p-paginator-prev:focus{
      box-shadow: none;
  } 
}