@use "../../../utils/variable.scss" as variable;

$dropzone-height: 204px;
$gap: 24px;
$icon-width: 40px;
$image-preview-width: 100px;

.dropzone-v2 {
  padding: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .label-upload-field-v2 {
    height: $dropzone-height;
  }

  &.upload-error {
    .label-upload-field-v2 {
      border-color: variable.$danger-color;
      background-color: rgba(variable.$danger-color, 0.2);
    }
  }

  &.active {
    .label-upload-field-v2 {
      border-color: variable.$color-light-blue;
      background-color: rgba(variable.$color-light-blue, 0.2);
    }
  }

  .subtitle {
    color: variable.$black;
  }

  .error {
    color: variable.$danger-color;

    ~ .media_type_wrapper {
      .circle-text {
        color: variable.$danger-color;
        background: rgba(variable.$danger-color, 0.1);
      }
    }
  }

  .media_type_wrapper {
    gap: $gap;
  
    .circle-text {
      color: variable.$color-light-blue;
      background: rgba(variable.$color-light-blue, 0.1);
      padding: 10px 0;
      border-radius: 50%;
      height: $icon-width;
      max-width: $icon-width;
      width: $icon-width;
      font-size: variable.$text-min-size;
      text-align: center;
      line-height: 20px;
      font-weight: 700;
    }
  }
}

.preview-file-container-v2 {
  overflow-y: scroll;
  max-height: calc(100vh - $dropzone-height - 164px - $gap - $gap);

  .file-review-v2 {
    max-width: 100%;
    border-bottom: 1px solid variable.$background-color-sidebar;
    border-top: 1px solid variable.$background-color-sidebar;

    .file-review-item {
      display: flex;
      padding: 16px 0px;
      width: 100%;
      overflow: hidden;
    }

    .file-review-img {
      
      object-position: center;
      aspect-ratio: 1;
      border-radius: 20px;
      height: $image-preview-width;
      min-width: $image-preview-width;
      width: $image-preview-width;
      padding: 25px;
      object-fit: contain;
      background: variable.$background-color-sidebar;

      &.file-review-img-bg-svg,
      &.file-review-img-bg-jpg,
      &.file-review-img-bg-jpeg {
        object-fit: cover;
        padding: 0;
      }

      &.file-review-img-bg-png {
        object-fit: contain;
        padding: 0;
      }
    }

    .file-review-item-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      .file-extension {
        padding: 2px 4px;
        border-radius: 4px;
        background: variable.$icon-disabled;
        text-transform: uppercase;
        color: variable.$white;
        font-size: variable.$text-min-size;
      }

      .file-review-item-info-name {
        font-size: variable.$text-default-size;
        font-weight: 500;
        margin: 0px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .file-review-item-info-size {
        font-size: variable.$text-default-size;
        font-weight: 500;
      }
    }
  }
}
