.create-coupon-form {
  .p-calendar{
    display: flex !important;
  }

  .currency-selectbutton {

    .p-selectbutton {
      width: 100%;
      .p-button {
        width: calc(100% / 3);
      }
    }
  }
}
