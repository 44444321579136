
@use '../../../utils/variable.scss' as variable;

.p-inputgroup-container {
    .p-inputgroup-addon {
        max-height: 48px;
    }

    input { 
        border-top-left-radius: 16px!important;
        border-bottom-left-radius: 16px!important;
        border-radius: 16px!important;
        padding: 0.5rem 0.75rem!important;
    }
}

.dropdown-cms-hyperlink {
    max-width: 500px;
    font-size: 15px;

    .p-dropdown-header {
        font-size: 14px;

        .p-dropdown-filter {
            font-size: 15px;
            padding: 8px;

            &:focus {
                border-color: variable.$second-green-color !important;
            }
        }
    }
}

.search-global {    
    .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 10px;
    }

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        outline: none;
        box-shadow: none;
    }

    .label {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        color: variable.$light-slate-gray;
        padding-bottom: 4px;
        position: relative;
        padding-right: 8px;
        font-style: italic;

        .required-label {
          color: variable.$red-error;
        }
    }

    .search-form-element {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
        padding: 10px;
        font-size: 12px;
    
        input:disabled, textarea:disabled {
            background: variable.$background-color-white-smoke !important;
        }
    
        .apply-btn {
            padding: 0 0 10px 0 !important;
        }
    
        .search-form-element-wrapper {
            margin-bottom: 10px;
        }
    
        textarea {
            border: 1px solid variable.$background-color-white-gray;
            color: variable.$black-gray-color;
            border-radius: 16px;
            height: 48px;
            padding: 8px 16px;
            width: 100%;
            background: variable.$background-color-white;
            text-align: left;
            position: relative;
            min-height: 144px;
            &:focus-visible {
              outline: none;
              border: 1px solid variable.$second-green-color !important;
            }
          }
    
        input {
            border: 1px solid variable.$background-color-white-gray;
            color: variable.$black-gray-color;
            border-radius: 16px;
            height: 48px;
            padding: 8px 16px;
            width: 100%;
            background: variable.$background-color-white;
            text-align: left;
            position: relative;
            &:focus-visible {
              outline: none;
              border: 1px solid variable.$second-green-color !important;
            }
          }
          
          .has-error {
            border: 1px solid variable.$red-error;
            border-radius: 16px;
          }
          
          .error {
            position: relative;
            color: variable.$red-error;
            letter-spacing: 1px;
            text-transform: uppercase;
            line-height: 1.3;
            font-size: 12px;
            font-weight: 400;
            padding: 10px 0px 0px 24px;
          
            &::before {
              display: block;
              content: url(../../../images/errorIcon.svg);
              position: absolute;
              left: 0px;
              top: 8px;
              width: 18px;
              height: 18px;
            }
          }
    
        .form-control {
            font-size: 13px;
        }
    
        .search-form-element-title {
            margin-bottom: 5px;
        }
    
        .form-item-accordion-tab {
            display: flex;
            width: 100%;
    
            .form-item-header {
                flex: 1;
                max-height: 40px;
                overflow: hidden;
    
                p {
                    margin: 0;
                }
            }
        }
    }
}


