@use "../../../utils/variable.scss" as variable;

.user-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid variable.$background-color-sidebar;
    
    .title {
        font-size: variable.$text-h5-size !important;
        font-weight: 500 !important;
        color: variable.$black;
    }

    .account {
        display: flex;
        align-items: center;
        .notifications {
            position: relative;
            i {
                font-size: variable.$text-h2-size;
            }

            .dot-noti {
                position: absolute;
                right: -8px;
                top: -8px;
                width: 12px;
                height: 12px;
                border-radius: 8px;
                background-color: variable.$primary-color;
            }
        }

        .partner {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                height: 40px;
                width: auto;
                object-fit: contain;
                object-position: center;
            }
        }

        .user {
            width: 48px;
            height: 48px;
            cursor: pointer;
            overflow: hidden;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important;
            }

            .user-no-avatar {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: variable.$white;
                font-weight: 500;
                font-size: variable.$text-h4-size;
                background-color: variable.$background-avatar;
            }
        }
    }
}