.tag-sponsor-custom {
    padding-top: 1px;
    .p-tag {
        border-radius: 20px;
        min-width: 90px;
        .p-tag-value {
            font-size: 12px;
            text-transform: capitalize;
        }
    }
}