.tox-tinymce {
  border: none!important;
  box-shadow: 0px 0px 4px 0px variable.$box-shadow-color !important;

  &:not(.tox-tinymce--disabled):focus, &:not(.tox-tinymce--disabled):hover {
      border-color: variable.$box-shadow-hover-color !important;
      box-shadow: 0px 0px 2px 0px variable.$box-shadow-hover-color !important;
  }

  &.tox-tinymce--disabled {
    background-color: variable.$bg-disabled-color;
    box-shadow: none;
  }
}