@use "../../../../utils/variable.scss" as variable;
@import "../../../../components_v2/utils/style-common.scss";

.user-table {
  display: flex;
  flex-direction: column;
  
  .p-datatable {
    .p-datatable-table {
      .role-user {
        text-transform: capitalize;
      }

      @extend .avatar-user;

      .user-avatar {
        .avatar {
          border-radius: 72px;
          box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
        }
      }

      .user-action-avatar {
        img {
          height: 20px;
          width: 20px;
          object-fit: cover;
          object-position: center;
        }
      }

      .user-by {
        text-align: center;
        .user-action {
          display: flex;
          justify-content: center;
          color: variable.$color-time;
        }
      }
    }
  }
}
