$header-size: 154px;
$padding: 24px;
$border-width: 1px;

.media-manager-container-v2 {
  &.media-manager-container-dialog-v2 {
    .header {
      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px solid variable.$background-color-sidebar;
        // border-top: 1px solid variable.$background-color-sidebar;

        .search-container {
          display: flex;
          align-items: center;

          .filter-btn {
            .filter-btn-content {
              cursor: pointer;
              border-radius: calc(variable.$primary-element-height / 2);
              background: variable.$white;
              box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
              height: variable.$primary-element-height;
            }
          }
        }
      }
    }

    .media-wrapper {
      padding-bottom: $padding;

      .p-splitter {
        height: calc(92vh - 88px - 48px + 4px);

        .directory-container .p-tree {
          height: calc(92vh - 88px - 48px - $border-width * 2 + 4px) !important;
        }
      }

      .custom-style-grid {
        .p-datascroller-content {
          background: variable.$transparent;
          padding: 0;
          max-height: calc(92vh - 88px - 48px - $border-width * 2 + 4px) !important;
        }
      }
    }
  }

  .media-content {
    padding: $padding;
  }

  .p-splitter {
    height: calc(100vh - $header-size - $padding * 2);
    border: 0;
    border-radius: 0px;
    
    .p-splitter-gutter {
      display: none;
    }

    .p-splitter-panel {
      overflow-y: auto;
      border: 1px solid variable.$background-color-sidebar;
      border-right: none;
    }

    .directory-container .p-tree {
      height: calc(100vh - $header-size - $padding * 2 - $border-width * 2) !important;
    }

    .file-panel-items {
      background: variable.$background-header;
      border: none;
      position: relative;

      .media-loading-component {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
    }
  }

  .custom-style-grid {

    &.grid-6-col .p-datascroller-content .p-datascroller-list  {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .p-datascroller-content {
      background: variable.$transparent;
      padding: 0;
      max-height: calc(100vh - $header-size - $padding * 2 - $border-width * 2) !important;

      .p-datascroller-list {
        display: grid;
        grid-gap: 2px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        padding: 0;

        > li {
          border: none;
        }
      }
    }
  }
}
