@use "../../../../utils/variable.scss" as variable;
@import "../../../../components_v2/utils/style-common.scss";

.user-form {
    width: 100%;
    height: calc(100vh - 84px - 73px);
    overflow-y: auto;

    .user-form-container {
        width: 552px;
        margin: auto;
        @extend .avatar-user;
        @extend .row-common;

        .user-column {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .user-image {
                padding: 10px;
                border-radius: 72px 72px 32px 32px;

                &.background-user {
                    background-color: variable.$background-header;
                }

                .user-avatar {
                    position: relative;
                    z-index: 1;

                    .background-edit-user {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 2;
                        background-color: variable.$background-edit-avatar;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;

                        svg {
                            width: 24px;
                            height: 24px;
                            path {
                                stroke: variable.$white;
                            }
                        }
                    }
                }

                .user-avatar,
                .user-avatar-default {
                    width: 104px;
                    height: 104px;
                    border-radius: 72px !important;
                    font-size: 32px !important;
                    box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;

                    .user-role {
                        right: 10px;
                        width: 24px;
                        height: 24px;
                        z-index: 3;
                    }

                    img {
                        border-radius: 72px;
                    }
                }
            }
        }
    }
}

.wi-dropdown-language-panel {
    .wi-language-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .wi-language-label {
            height: 24px;
        }

        .wi-language-icon {
            width: 24px;
            min-width: 24px;
            height: 24px;
            display: flex;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
