@use "../../../../../utils/variable.scss" as variable;
@import '../../../../../components_v2/utils/style-common.scss';

.media-details {
  .fixed-frame-image {
    height: 500px;
    width: 500px;
  }

  .row-content {
    @extend .row-common;
  }

  .file-preview {
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
    background: variable.$background-header;
    
    img {
      object-fit: contain;
      object-position: center;
      max-height: 337px;
    }

    .image-w-90 {
      width: 100%;
    }

    .image-w-25 {
      width: 25%;
    }

    .image-bg-svg, .image-bg-png, .image-bg-jpeg, .image-bg-jpg {
      background: variable.$gainsboro;
    }
  }

  .small-circle {
    min-width: 6px;
    width: 6px;
    height: 6px;
    background: variable.$color-time;
    border-radius: 50%;
    margin: auto;
  }

  .txt-gray {
    color: variable.$color-time;
  }

  .txt-black {
    color: variable.$black;
  }

  .section-summary {
    background: variable.$background-header;
    font-size: variable.$text-default-size;
    font-weight: 500;
    color: variable.$black;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .button-panel {
    justify-content: center;
    gap: 24px;
    margin: 20px 0;
  }

  .section-media-properties {
    margin: 24px;
    background: variable.$background-color-sidebar;
    border-radius: 20px;
    font-size: variable.$text-default-size;
    font-weight: 500;
    color: variable.$black;
    overflow: hidden;
    
    .media-properties {
      display: flex;
      justify-content: space-between;
      padding: 12px;
  
      .media-main-properties {
        gap: 8px;
      }
  
      .media-extension {
        background: variable.$icon-disabled;
        padding: 2px 4px;
        font-size: variable.$text-min-size;
        font-weight: 400;
        border-radius: 4px;
        color: variable.$white;
        text-transform: uppercase;
      }
    }

    .media-camera-info {
      background: variable.$background-header;
      padding: 12px;
    }
  }


  .audit-info-text {
    font-size: variable.$text-default-size;
  }

  .p-accordion {
    .p-accordion-header-link {
      font-size: 13px;
      padding: 8px 15px;
    }

    .p-accordion-content {
      padding: 0px;
    }
  }

  .form-mode.form-mode-dialog .footer-panel .btn-submit {
    padding: 7px 30px;
    font-size: 16px;
    background: variable.$second-green-color;
    border: variable.$second-green-color;

    .p-button-icon {
      font-size: 14px;
    }

    .p-button-label {
      font-size: inherit;
    }
  }
}