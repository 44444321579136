@use '../../utils/variable.scss' as variable;


.partner-manager {
  .p-table-v2 {

    .logo-image {
      overflow: hidden;
      height: 72px;
      width: 140px;
      // padding: 12px;
      // box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
      // border-radius: 36px;

      img {
        object-fit: contain;
        object-position: center;
        height: 100%;
        width: 100%;
      }
    }

    .user-avatar {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;

      .user-avatar-default {
        height: 100%;
        background-color: variable.$background-avatar;
        display: flex;
        align-items: center;
        justify-content: center;
        color: variable.$text-color-white;
        font-weight: 400;
        font-size: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}