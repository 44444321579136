@use "../../../../src/utils/variable.scss" as variable;
@import "../../../../src/utils/style/style-common.scss";

.filter-coupon-detail-component {
  width: 100%;

  .wi-search-container {
    width: 192px;
    display: inline-block;

    .pi-search {
      font-size: 15px;
      // margin-top: 2px;
    }

    .p-input-icon-left {
      .p-inputtext {
        height: 30px !important;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 13px !important;
      }
    }
  }

  .filter-panel {
    padding: 5px 0px;
  }

  .p-button {
    color: variable.$lavender-color;
    background-color: variable.$white;
    padding: 5px 10px;
    font-size: 12px;
    border: 0.5px dashed variable.$color-darkgray;
    border-radius: 20px;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
  }

  .filter-container {
    text-align: left;
    flex: 1;
    margin: -5px;

    .wi-filter-option-coupon.p-button {
      margin: 5px !important;
    }

    .wi-filter-option-coupon.p-button:focus,
    .wi-filter-option-coupon.p-button.filter-active,
    .wi-filter-option-coupon.p-button:hover {
      color: variable.$text-color-white;
      background-color: variable.$second-green-color;
      border: 0.5px solid variable.$second-green-color;
    }

    .wi-filter-option-coupon.p-button.unvalid-date {
      color: variable.$text-color-white;
      background-color: variable.$red-error;
      border: 0.5px solid variable.$red-error;
    }

    .wi-filter-option-coupon.p-button:nth-child(even) {
      margin: 0 5px;
    }

    .wi-filter-option-coupon.p-button:nth-child(odd) {
      margin: 0rem;
    }
  }

  .remove-filter-button .p-button {
    border: none;
    .p-button-icon {
      font-size: 12px;
    }
  }

  .remove-filter-button .p-button:focus,
  .remove-filter-button .p-button:hover {
    color: variable.$text-color-gray;
    background-color: variable.$white;
  }

  .tabmenu-coupon {
    .p-tabmenu-nav .p-tabmenuitem {
      .p-menuitem-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }

      .p-menuitem-link {
        padding: 0.75rem;
        margin: 0;

        .p-menuitem-text {
          font-size: 14px;
        }
      }

      &.p-highlight .p-menuitem-link {
        border-color: variable.$second-green-color;
        color: variable.$second-green-color;
      }
    }
  }
}

.overlaypanel-filter-coupons .p-overlaypanel-content {
  padding: 0 0.5rem;
  margin: 0;

  .p-checkbox-icon {
    font-size: 12px;
    font-weight: 700;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }

  .p-listbox-list-wrapper {
    max-height: 600px!important;
  }

  .p-listbox.p-component {
    padding: 0;
    margin: 0;
    width: 100%;
    border: 0;

    .p-listbox-list {
      padding: 0.5rem 0rem;
      font-size: 14px;

      .p-listbox-item {
        padding: 5px;

        &:focus {
          box-shadow: none;
        }

        &.p-highlight {
          color: variable.$second-green-color;
          // background-color: variable.$background-color-white-smoke;
        }

        .p-checkbox {
          align-items: center;

          .p-checkbox-box {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .p-calendar {
    padding: 0;
    width: 100%;
    .p-datepicker {
      border: 0;
      .p-datepicker-header {
        padding: 0.25rem;
        .p-datepicker-next .pi,
        .p-datepicker-prev .pi {
          font-size: 14px;
          box-shadow: none;
        }
        .p-datepicker-next:focus,
        .p-datepicker-prev:focus {
          box-shadow: none;
        }
        .p-datepicker-title {
          line-height: 0;
          .p-datepicker-month,
          .p-datepicker-year {
            font-size: 14px;
          }
        }
      }
      .p-datepicker-calendar-container .p-datepicker-calendar thead tr th,
      .p-datepicker-calendar-container .p-datepicker-calendar tbody tr td {
        padding: 0.25rem;
        font-size: 14px;
        text-align: center;
      }
    }
    .p-datepicker:not(.p-disabled)
      table
      td
      span:not(.p-highlight):not(.p-disabled):focus {
      box-shadow: none;
    }
    .p-datepicker table td > span.p-highlight {
      background-color: variable.$light-green-row;
      color: variable.$text-color-gray;
      box-shadow: none;
    }
    .p-datepicker table td > span:focus {
      box-shadow: none;
    }
  }
}
