@use "../../../utils/variable.scss" as variable;

$term-logo-height: 53px;
$term-logo-height2: -53px;
$term-logo-mb: 56px;

.term-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center; /* align item horizontally */
  align-items: center; /* align item vertically */

  .background-cover, .background-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
  }

  .background-backdrop {
    background: rgba(variable.$black, 50%);
  }

  .term-form-wrapper {
    margin: 0 auto;
    z-index: 2;
    margin-top: calc($term-logo-height2 - $term-logo-mb);

    .term-logo {
      object-fit: contain;
      object-position: center;
      height: $term-logo-height;
      margin-bottom: $term-logo-mb;
    }

    .term-form {
      border-radius: 48px;
      background: variable.$white;
      display: flex;
      flex-direction: column;
      width: 620px;

      .term-title {
        font-family: "Poppins", sans-serif;
      }

      .term-content {
        text-align: left;
      }

      .term-text {
        font-size: variable.$text-h5-size;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: variable.$black;
        line-height: 28px;

        .strong-text {
            font-weight: 700;
            font-family: "Poppins", sans-serif;
        }

        .term-link {
            color: variable.$primary-color;
            font-weight: 700;
            text-decoration: underline;
            font-family: "Poppins", sans-serif;
        }
      }

      .btn-term {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .wi-primary-button-v2 {
            font-weight: 500;
            width: 146px;
            padding: 10px 48px;
          }
      }
    }
  }
}
