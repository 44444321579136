@use "../../utils/variable.scss" as variable;
@import "../../utils/style/style-common.scss";

$report-height: 1700px;

.report-container {
  height: $report-height;
  width: 100%;
}

.report-layout {
  position: relative;
  height: calc(100vh - 81px);
  overflow-y: auto;
  overflow-x: hidden;

  .loading-component {
    height: $report-height;
    align-items: normal;
    .p-progress-spinner {
      top: 12%
    }
  }

  .main-loading-component {
    position: absolute;
    width: 100%;
    height: $report-height;
    display: flex;
    justify-content: center;
    align-items: normal;
    background: variable.$background-color-white;
    z-index: 9999;

    .p-progress-spinner-circle {
      stroke: variable.$second-green-color !important;
    }
    .p-progress-spinner {
      top: 0;
      max-height: calc(100vh);
    }

    &.bg-white-10 {
      background: rgba(variable.$background-color-white, 0.5);
    }
  }
}
