@use '../../../../utils/variable.scss' as variable;

.create-quote-dialog {
    .p-dialog-header {
      background-color: variable.$second-green-color;
      color: variable.$text-color-white;
    }
  
    .p-dialog-header-icons .p-dialog-header-icon {
      color: variable.$text-color-white;
    }
    .btn-submit {
      &.p-button {
          border-color: variable.$second-green-color;
          background: variable.$second-green-color;
          border-radius: 20px;
          font-size: 13px;
          font-weight: 700;
          padding: 10px 30px;
  
          &:focus {
              box-shadow: none;
          }
  
          &:hover {
              background-color: variable.$second-yellow-color;
              border-color: variable.$second-yellow-color;
              color: variable.$second-green-color;
          }
      }
    }
  }