@use "../../../../utils/variable.scss" as variable;
.p-dialog-mask .dialog-teams-and-runners-container {
  .label {
    font-size: 14px;
    text-transform: uppercase;
    color: variable.$light-slate-gray;
    font-style: italic;
    margin-bottom: 10px;
  }

  .teams-and-runners-container.upload-file {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;

      .label-upload-csv {
        width: 75%;
        padding: 0.5rem;
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
        border-radius: 25px;
        cursor: pointer;
        text-align: center;
      }

      .name-file {
        padding-left: 20px;
      }
    }
  
  .btn-submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: variable.$background-color-white;
    margin-top: 2rem;

    button {
      border-color: transparent;
      background-color: variable.$primary-green-color !important;
      color: variable.$second-yellow-color !important;
      padding: 0.5rem 1.5rem;
      min-height: 2rem;
      height: 2rem;
      font-family: variable.$font-name-Sora;
      font-size: 14px;

      &:hover {
          background-color: variable.$second-yellow-color !important;
          color: variable.$primary-green-color !important;
          border-color: variable.$second-yellow-color !important;
      }
    }
  }
}
