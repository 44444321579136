@use "../../../../utils/variable.scss" as variable;

.donation-detail {
    height: calc(100vh - 81px - 16px * 2);
    overflow-y: auto;

    .p-datatable .p-paginator-bottom {
        border: none !important;
    }

    .wi-status-subscription {
        margin: 0 !important;
        width: max-content;
    }

    .wi-form-displayed-value {
        font-weight: 500 !important;
    }

    .h3 {
        font-size: variable.$text-h3-size !important;
        font-weight: 500 !important;
    }

    .rhythm-icon {
        color: variable.$primary-color;
        font-size: variable.$text-default-size;
        padding-left: 6px;
    }

    .dropdown_partners_edit{
        .p-dropdown-panel{
            max-width: 300px !important;
        }
    }

    &__header {
        position: sticky;
        top: 0px;
        left: 0;
        right: 0;
        background-color: variable.$white;
        z-index: 2;

        .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;

            .h1 {
                font-weight: 500;
            }

            .btn-action-container {
                display: flex;

                .donation-serial {
                    font-weight: 500;
                    align-content: center;
                }
            }
        }

        .row-amount {
            margin: 0;
            padding: 0;
            border-bottom: 0.5px solid variable.$label-color;
        }

        .amount {
            display: flex;
            justify-content: space-between;
            padding: 0;

            .amount-container {
                display: flex;
                align-items: center;

                .value-amount,
                .currency-amount {
                    font-size: 28px;
                    padding-right: 5px;
                }

                .value-amount {
                    font-weight: 500;
                }

                .currency-amount {
                    color: variable.$value-color;
                }
            }
        }
    }

    &__information {
        text-align: left;
    }

    &__information-title {
        border-bottom: 0.5px solid variable.$label-color;
    }

    &__information-content {
        padding-top: 16px;

        .content-row {
            margin-bottom: 8px;
            min-height: 30px;

            .col-md-5,
            .col-md-8,
            .col-md-7,
            .col-md-4 {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .edit-donation-field {
                width: 100%;

                input {
                    padding: 3px 10px 3px 10px;
                    font-size: variable.$text-default-size;
                    width: 100%;
                    border-radius: 10px;
                    border: 0.5px solid variable.$color-darkgray;
                    box-shadow: none !important;
                    outline: none !important;
                }

                .p-dropdown {
                    height: 30px;
                    width: 100%;
                    border-radius: 10px;
                    border: 0.5px solid variable.$color-darkgray;
                    box-shadow: none !important;
                    outline: none !important;

                    .p-dropdown-label,
                    .p-dropdown-trigger-icon {
                        padding: 3px 0 3px 10px;
                        font-size: variable.$text-default-size;
                        color: variable.$black;
                    }
                }

                .p-inputtextarea {
                    border: 0.5px solid variable.$color-darkgray;
                    font-size: variable.$text-default-size;
                    width: 100%;
                    color: variable.$black;
                }

                .p-calendar {
                    width: 100%;
                }

                .has-error {
                    border: 0.5px solid variable.$color-error;
                }

                .error {
                    color: variable.$color-error;
                    font-size: variable.$text-min-size;
                    display: block;
                }
            }
        }
    }

    .p-timeline {
        max-height: 400px;
        overflow-y: auto;

        .p-timeline-vertical .p-timeline-event-connector {
            width: 1px !important;
        }

        .p-timeline-event {
            .p-timeline-event-separator {
                .custom-marker {
                    display: flex;
                    width: 20px;
                    height: 20px;
                    min-height: 20px;
                    align-items: center;
                    justify-content: center;
                    color: variable.$background-color-white;
                    border-radius: 50%;
                    z-index: 1;

                    i {
                        font-size: variable.$text-min-size;
                    }
                }
            }

            .p-timeline-event-opposite {
                display: none;
            }
        }

        .p-timeline-event-content {
            .custome-content-timeline {
                font-size: variable.$text-default-size;
                .title-event {
                    color: variable.$label-color-donation;
                    font-weight: 500;
                }

                .message-event,
                .date-event {
                    color: variable.$description-color;
                    font-size: variable.$text-min-size;
                }

                .date-event {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .donation-detail__header {
        left: 0 !important;
        h3 {
            margin: 0;
        }

        .header-container {
            padding-right: 0 !important;
        }
    }
}
