@use "../../../utils/variable.scss" as variable;
@import "../../utils/style-common.scss";

$icon-height: 32px;
$border-radius: 20px;
$icon-padding: 4px;

.no-mouse-events {
  pointer-events: none;
}

.main-container-v2 {
  min-height: 100vh;
  height: 100vh;
  display: flex;

  *:not(.pi):not(.fa-solid) {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: variable.$text-default-size;
  }

  .icon-svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  .content {
    width: calc(100% - 96px);
    max-width: calc(100% - 96px);
    margin-left: 96px;

    .p-table-v2 {
      height: calc(100vh - 235px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 235px);
      }

      th.p-align-right {
        justify-content: flex-end;
      }

      .table-ref-link-cell {
        text-decoration: none;
        color: variable.$primary-link-color;
        font-weight: 500;
        
        &:hover {
          color: variable.$primary-link-hover-color;
        }
      }

      .cell-info {
        .cell-main-info {
          font-weight: 400;
          font-size: variable.$text-default-size;
        }

        .cell-sub-info {
          font-weight: 400;
          font-size: variable.$text-min-size;
          color: variable.$color-time;
        }
      }
    }

    .p-datatable {
      width: 100%;
      .p-datatable-table {
        .p-datatable-thead {
          tr th {
            background-color: variable.$white;
            color: variable.$black;
            padding: 12px;
            outline: none!important;
            box-shadow: none!important;

            &.p-align-center {
              display: flex;
              justify-content: center;
            }
            .p-column-title {
              font-weight: 500;
            }

            &.p-sortable-column {
              &.p-highlight {
                .p-sortable-column-icon {
                  color: variable.$primary-color!important;
                }
              }
              .p-sortable-column-icon {
                font-size: variable.$text-default-size;
              }
            }
          }
        }

        .p-datatable-tbody {
          tr {
            &:hover {
              background-color: variable.$transparent;

              td {
                background-color: variable.$color-hover;
              }
            }

            &.p-highlight {
              background-color: variable.$transparent;

              td {
                background-color: rgba(variable.$primary-color, 0.1);
              }
            }

            td {
              color: variable.$black;
              padding: 12px;

              &:last-child {
                background: variable.$white !important;
              }
            }
          }
        }
      }
    }
  }

  .header {
    background-color: variable.$white;

    .headline {
      text-align: left;
    }

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid variable.$background-color-sidebar;

      .search-container {
        display: flex;
        align-items: center;
        
        .filter-btn {
          .filter-btn-content {
            cursor: pointer;
            border-radius: 20px;
            background: variable.$white;
            box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
            height: variable.$primary-element-height;
          }
        }
      }
    }
  }

  .wi-status-active {
    background-color: rgba(variable.$color-light-green, 0.1);
    .wi-language-label,
    .p-dropdown-trigger-icon {
      color: variable.$color-light-green;
    }
  }

  .wi-status-archived {
    background-color: rgba(variable.$color-gray, 0.1);
    .wi-language-label,
    .p-dropdown-trigger-icon {
      color: variable.$color-gray;
    }
  }

  .wi-status-new {
    background-color: rgba(variable.$color-light-blue, 0.1);
    .wi-language-label,
    .p-dropdown-trigger-icon {
      color: variable.$color-light-blue;
    }
  }

  .wi-status-inactive {
    background-color: rgba(variable.$color-orange, 0.1);
    .wi-language-label,
    .p-dropdown-trigger-icon {
      color: variable.$color-orange;
    }
  }
}

@media (min-width: 1681px) {
  .main-container-v2 {
    width: 60%;
    min-width: 1680px;
    margin: auto;
    .sidebar-menu {
      position: relative !important;
      .sidebar-menu-content {
        width: 240px !important;
        transition: unset !important;
        &:hover {
          width: 240px !important;
        }

        .content-menu {
          padding: 0 12px !important;
        }

        .logo {
          width: 120px !important;
          .logo-menu {
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
          }
          .logo-menu-prod{
            background-image: url('../../../assets/images/logo/logo_menu.svg') !important;
          }
          .logo-menu-none-prod{
              background-image: url('../../../assets/images/logo/dev-logo.svg') !important;
          }
        }
        .footer-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          border-radius: 12px;
          background-color: variable.$background-header;
          .name {
            display: block !important;
          }
          .avatar {
            padding: 8px !important;
            margin-right: 40px !important;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .content {
      width: 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      width: calc(60vw - 241px) !important;
      min-width: calc(1680px - 241px) !important;
    }
  }
}

.wi-tag-cyan {
  color: variable.$color-light-cyan;
  background: rgba(variable.$color-light-cyan, 0.1);
}

.wi-tag-teal {
  color: variable.$color-teal;
  background: rgba(variable.$color-teal, 0.1);
}

.wi-tag-role {
  padding: 6px 12px;
  border-radius: 8px;
  background: rgba(variable.$primary-color, 0.1);
  color: variable.$primary-color;
}

@import './dropdown';
@import './multiselect';
@import './sidebar';
@import './menu';
@import './loading-spinner';
@import './tinycme';
@import './calendar';
@import './dialog';
@import './media-container';
@import './checkbox';
@import './tab-menu';
@import './tooltip';

// .p-dialog.wi-loading-v2 {
//   border-radius: 30px;
//   .p-dialog-header {
//     display: none;
//   }

//   .p-dialog-content {
//     border-radius: 30px;
//     padding: 24px 116px;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .loading-content {
//       width: 100%;
//       text-align: center;

//       .progress-bar {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//       }

//       .p-progress-spinner.progress-spinner-v2 {
//         background-image: unset !important;
//         .p-progress-spinner-svg {
//           .p-progress-spinner-circle {
//             stroke: variable.$second-green-color !important;
//           }
//         }
//       }

//       .message-loading {
//         font-weight: 700;
//         font-size: variable.$text-default-size;
//         font-family: 'Poppins', sans-serif;
//         color: variable.$black;
//       }
//     }
//   }
// }

// .p-dropdown-panel {
//   border-radius: 20px;
//   .p-dropdown-items-wrapper {
//     border-radius: 20px;
//     .p-dropdown-items {
//       padding: 0;
//       .p-dropdown-item {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         border-bottom: 1px solid variable.$background-header;

//         &:first-child {
//           border-radius: 20px 20px 0 0;
//         }

//         &:last-child {
//           border-radius: 0 0 20px 20px;
//           border-bottom: 0;
//         }
//       }
//     }
//   }
// }
