@use "../../../utils/variable.scss" as variable;



.warning-dialog {
  &.info-dialog {
    .p-dialog-content {
      .confirmation-content {
        i {
          background-color: variable.$second-green-color!important;
        }
      }
    }

    .p-dialog-footer {
      .button-confirm {
        background-color: variable.$second-green-color!important;
        border: 1px solid variable.$second-green-color!important;

        &:hover {
          color: variable.$second-green-color!important;
          background-color: transparent!important;
        }
      }
    }
  }

  .p-dialog-header {
    background-color: variable.$red-icon!important;
    display: none;
  }

  .p-dialog-content {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    align-items: flex-start!important;
    padding: 10px 20px!important;

    .confirmation-content {
      align-items: flex-start!important;;
      line-height: 1.3;
      
      i {
        text-align: center;
        font-size: 28px!important;
        background-color: variable.$red-icon!important;
        opacity: 0.7;
        width: 50px;
        min-width: 50px;
        height: 50px;
        padding: 10px!important;
        line-height: 28px;
        margin-right: 20px!important;
      }
    }
  }

  .p-dialog-footer {
    padding: 10px 20px!important;

    .p-button {
      font-family: Sora;
      font-size: 13px;
      height: 36px;
      border-radius: 20px;
    }

    .button-hide {
      background-color: transparent!important;
      color: variable.$gray-color!important;
      border-color: variable.$gray-color!important;

      &:hover {
        color: variable.$text-color-white!important;
        background-color: variable.$gray-color!important;
      }
    }

    .button-confirm {
      background-color: variable.$red-icon!important;
      color: variable.$text-color-white!important;
      border: 1px solid variable.$red-icon!important;
      margin-right: 0px!important;
  
      &:hover {
        color: variable.$red-icon!important;
        background-color: transparent!important;
      }
    }
  }
}

.wi-confirm-dialog {
  .p-dialog-header {
    padding: .5rem 1.5rem;
    width: 100%;
    background-color: variable.$light-pink;
    color: variable.$text-color-white;
  }

  .p-dialog-header-icons .p-dialog-header-icon {
    color: variable.$text-color-white;
  }

  .p-dialog-content .confirmation-content {
    padding: 0;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    i {
      margin-right: 10px;
      font-size: 21px;
      font-weight: bold;
      color: variable.$text-color-white;
      background: variable.$red-icon;
      padding: 7px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .p-dialog-footer {

    button {
      border: 0;
      outline: 0;
      box-shadow: none;
      
      &.button-hide {
        background-color: variable.$background-color-white!important;
        color: variable.$second-green-color!important;
  
        &:hover {
          background-color: variable.$background-color-white!important;
          color: variable.$second-green-color!important;
          
        }
      }

      &.button-confirm {
        background-color: variable.$red-icon!important;
        color: variable.$text-color-white!important;
    
        &:hover {
          background-color: variable.$red-icon!important;
          color: variable.$text-color-white!important;
        }
      }
    }
  }
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: variable.$font-name-Sora;
}