$item-height: 24px;

.wi-calendar-panel-v2 {
}

.wi-calendar-v2.p-calendar {
  font-family: "Poppins", sans-serif;

  *:not(.pi):not(.fa-solid) {
    font-family: "Poppins", sans-serif;
  }

  .p-button.p-datepicker-trigger {
    height: 40px;
    border: none;
    width: 20px;
    position: absolute;
    right: 1rem;
    z-index: 2;
    top: 1px;
    color: variable.$primary-green-color;
    background: transparent!important;

    &:focus {
        border: none;
    }
  }

  .p-datepicker {
    .p-datepicker-group-container .p-datepicker-calendar .p-highlight {
      box-shadow: none!important;
      color: variable.$primary-color!important;
      background: rgba(variable.$primary-color, 0.1)!important;
    }

    .p-yearpicker {
      .p-yearpicker-year {
        box-shadow: none!important;
        &.p-highlight {
          color: variable.$primary-color!important;
          background: rgba(variable.$primary-color, 0.1)!important;
        }
      }
    }

    .p-monthpicker {
      .p-monthpicker-month {
        box-shadow: none!important;
        &.p-highlight {
          color: variable.$primary-color!important;
          background: rgba(variable.$primary-color, 0.1)!important;
        }
      }
    }

    .p-yearpicker-year .p-highlight {
      box-shadow: none!important;
      color: variable.$primary-color!important;
      background: rgba(variable.$primary-color, 0.1)!important;
    }

    .p-datepicker-header {
      .p-datepicker-prev, .p-datepicker-next {
        box-shadow: none!important;
      }
  
      .p-datepicker-title {
        font-size: calc(variable.$text-default-size);

        .p-datepicker-month, .p-datepicker-year {
          font-size: calc(variable.$text-default-size);
          padding: 0px 5px;
          
          &:enabled:focus, &:enabled:hover {
            color: variable.$primary-color!important;
          }
        }
      }
    }

    .p-datepicker-calendar {
      th {
        padding: 5px;

        > span {
          width: $item-height;
          height: $item-height;
          font-size: calc(variable.$text-default-size - 1px);
        }
      }

      td {
        padding: 5px;

        > span {
          width: $item-height;
          height: $item-height;
          font-size: calc(variable.$text-default-size - 1px);
        }
      }
    }
  }
}