@use "../../utils/variable.scss" as variable;

.donation-manager {
  .p-table-v2 {
    .rhythm-icon {
      font-size: 14px;
      padding-left: 5px;
      color: variable.$second-green-color;
    }

    .disabled-donation-row {
      .p-checkbox-box {
        background: variable.$background-backdrop;
        pointer-events: none;

        &.p-focus {
          box-shadow: 0px 0px 2px 0px variable.$box-shadow-color!important;
          border-color: transparent!important;
        }
      }
    }
  }
}
