@import '../../../../../utils/style/style-common.scss';

.header-item-editor-container {
    @extend .form-content;

    .label {
        text-align: left;
    }

    .header-action-footer {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 20px;

        .btn-save-header-item {
            min-width: 100px;
        }
    }

    .label-upload-field {
        padding: 0 !important;
    }

    .image-cert.media-preview {
        object-fit: cover;
        object-position: center;
    }
}