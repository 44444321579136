@use '../../../../utils/variable.scss'as variable;

.codes-receipt-form {
    .receipt-form-container {

        .row-content {
            
            .element-form {
                padding: 5px 0px;

                .label-upload-field {
                    &.certificate_logo {
                      padding: 0;
                      margin: 0;
                      width: 100% !important;
                      height: 126px !important;
                      min-height: 126px !important;
                      margin-left: 0 !important;
              
                      .image-cert {
                        width: 100%;
                        height: 124px;
                        object-fit: cover;
                        object-position: 50% 50%;
                        border-radius: 8px;
                      }
                    }
                }

                .p-selectbutton {
                    .p-button {
                        padding: 8px 12px!important;
                    }
                }

                .p-inputtextarea {
                    width: 100%;
                    padding: 5px 10px;
                }

                .asterik {
                    color: variable.$color-error;
                    // font-size: 14px;
                }

                .p-inputtext {
                    font-size: 14px;
                    outline: none;
                    box-shadow: none;
                }

                .input-upload {
                    display: none;
                }

                .donation-checkbox.checkbox-container {
                    input {
                        display: none;
                    }
                }
                
                .donation-checkbox.checkbox-container input[type=checkbox] + label {
                    svg {
                        width: 26px;
                    }
                
                    span {
                        font-size: 15px;
                    }
                }

                label {
                    display: block;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: variable.$light-slate-gray;
                    padding-bottom: 4px;
                    position: relative;
                    padding-right: 8px;
                    font-style: italic;
                }
        
                button {
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid variable.$background-color-white-gray;
                    color: variable.$black-gray-color;
                    border-radius: 16px;
                    height: 48px;
                    padding: 8px 16px;
                    width: 100%;
                    background: variable.$background-color-white;
                    text-align: left;
                    position: relative;
                    cursor: pointer;
        
                    &:focus {
                        box-shadow: none;
                        border: 1px solid variable.$second-green-color;
                    }
        
                    .chevron {
                        font-weight: bolder;
                        font-size: 15px;
                        transition: transform 0.3s ease-in-out;
                        .chevron-left {
                           position: absolute;
                           right: 23px;
                           top: 8px;
                           transform: rotate(45deg);
                        }
        
                        .chevron-right {
                            position: absolute;
                            right: 16px;
                            transform: rotate(-45deg);
                        }
        
                        .line-left {
                            position: absolute;
                            right: 27px;
                            transform: rotate(-45deg);
                        }
        
                        .line-right {
                            transform: rotate(45deg);
                        }
                    }
                }
        
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
        
                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }

                .p-inputnumber, .p-calendar {
                    width: 100%;
                }
        
                input {
                    border: 1px solid variable.$background-color-white-gray;
                    color: variable.$black-gray-color;
                    border-radius: 16px;
                    height: 40px;
                    padding: 5px 10px;
                    width: 100%;
                    background: variable.$background-color-white;
                    text-align: left;
                    position: relative;
                    &:focus-visible {
                        outline: none;
                        border: 1px solid variable.$second-green-color !important;
                    }
                }
        
                // .has-error {
                //     border: 1px solid variable.$red-error;
                //     border-radius: 16px;
                // }
        
                .dropdown-menu {
                    border: 1px solid variable.$second-green-color;
                    width: 100%;
                    border-radius: 16px;
                    margin: 10px 0px;
                }
                .error {
                    position: relative;
                    color: variable.$red-error;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    line-height: 1.3;
                    font-size: 12px;
                    font-weight: 400;
                    padding: 10px 0px 0px 24px;
        
                    &::before {
                        display: block;
                        content: url(../../../../images/errorIcon.svg);
                        position: absolute;
                        left: 0px;
                        top: 8px;
                        width: 18px;
                        height: 18px;
                    }
                }
                .description {
                    color: variable.$light-slate-gray;
                    padding: 5px 0px 0px;
                    font-size: 14px;
        
                    a {
                        color: variable.$second-green-color;
                        font-weight: 700;
                        text-decoration: underline;
                        transition: color .3s ease;
                    }
                }
            }
        }
    }
}

.expire-calendar {
    z-index: 2800!important;
}