@use "../../../../utils/variable.scss" as variable;
@import "../../../../utils/style/style-common.scss";

.global-content-dropdown {
  max-width: 50%;

  .p-dropdown-item {
    word-break: break-word;
    white-space: normal;
  }

  .global-page-item {
    display: flex;

    .global-page-picture {
      height: 100px;
      width: 150px;
      min-width: 150px;
      border-radius: 20px;

      > img {
        height: 100px;
        width: 150px;
        border-radius: 20px;
        object-fit: cover;
        object-position: center;
      }

      &.global-page-picture-circle {
        > img {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          margin: auto;
          margin: 0 25px;
          background: variable.$light-slate-gray;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .page-details {
      padding: 0 10px;

      .global-page-name {
        font-size: 15px;
        font-weight: 700;
        color: variable.$second-green-color;
      }

      .global-page-url {
        font-style: italic;
        color: variable.$light-slate-gray;
      }

      .global-page-description {
        overflow: hidden !important;
        text-overflow: ellipsis;
        height: 60px;
      }

      .global-page-position {
        // color: #13735f;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        text-overflow: ellipsis;
        width: 300px;
        white-space: nowrap;
        overflow: hidden !important;
      }
    }
  }
}

.apply-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: variable.$background-color-white;
  padding: 0px 10px 5px 10px;

  .btn-submit {
    margin: 5px 5px;
  }
  
  .select-group {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .global-item-label {
      p {
        margin: 0;
      }
    }

    .p-dropdown, .p-multiselect {
      flex: 1;
    }
    @extend .p-dropdown-common;
  }
}

.global-message {
  position: sticky;
  top: 0;
  z-index: 2;

  .p-message {
    margin: 8px 0px;

    .p-message-wrapper {
      padding: 10px;

      * {
        font-size: 16px;
      }
    }
  }
}