@use "../../../../utils/variable.scss" as variable;

.wi-block-confirm-dialog {
  .p-dialog-header {
    padding: 10px 20px;
    width: 100%;
    background-color: variable.$second-green-color;
    color: variable.$text-color-white;
  }

  .p-dialog-header-icons .p-dialog-header-icon {
    color: variable.$text-color-white;
  }

  .p-dialog-content .confirmation-content {
    padding: 0;
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;

    i {
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;
      color: variable.$text-color-white;
      background: variable.$second-green-color;
      padding: 7px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .p-dialog-footer {

    button {
      border: 0;
      outline: 0;
      box-shadow: none;
      padding: 10px 15px;
      font-size: 14px;
      
      &.button-hide {
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;

        &:hover {
          background-color: variable.$second-green-color;
          color: variable.$text-color-white;

        }
      }

      &.btn-leave {
        background-color: variable.$red-icon;
        color: variable.$text-color-white;

        &:hover {
          background-color: variable.$red-icon;
          color: variable.$text-color-white;
        }
      }

      &.button-confirm {
        background-color: variable.$background-color-white;
        color: variable.$second-green-color;
    
        &:hover {
          background-color: variable.$background-color-white;
          color: variable.$second-green-color;
        }
      }
    }
  }
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: variable.$font-name-Sora;
}

.wi-block-custom-dialog {
  .p-dialog-content .confirmation-content {
    margin: 0;
  }

  .p-dialog-footer {
    padding: 0 10px 10px 10px; 

    .p-button {
      border-radius: 20px;
      height: 36px;

      &.button-hide {
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
        min-width: 80px;

        &:hover {
          background-color: variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }

      &.btn-leave {
        background-color: transparent;
        border: 1px solid variable.$red-icon;
        color: variable.$red-icon;

        &:hover {
          background-color: variable.$red-icon;
          color: variable.$text-color-white;
        }
      }
      
      &.button-confirm {
        margin-right: 10px;
        border: 1px solid variable.$second-green-color;

        &:hover {
          background-color: variable.$second-yellow-color;
          border: 1px solid variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }
    }
  }
}