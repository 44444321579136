@use '../../../../utils/variable.scss' as variable;

.p-dialog {
    width: 60vw;
}

.create-receipt-dialog {
    .p-dialog-header {
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
        padding: 10px 1rem;

        .p-dialog-title {
            font-size: 20px;
        }
    }

    .p-dialog-header-icons .p-dialog-header-icon {
        color: variable.$text-color-white;
    }

    .p-dialog-content {
        padding-bottom: 10px;
        // min-height: 480px;
    }

    .p-selectbutton {
        .p-button {
            padding: 12px 20px;
    
            .p-button-label {
                font-size: 13px;
            }
    
            &:focus {
                box-shadow: none;
            }
    
            &.p-highlight {
                border-color: variable.$second-green-color;
                background-color: variable.$second-green-color;
    
    
                &:hover {
                    border-color: variable.$second-green-color;
                    background-color: variable.$second-green-color;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .p-dialog {
        width: 90vw;
    }
}

