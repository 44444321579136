.p-menu.menu-popup-v2 {
  border-radius: 20px!important;
  overflow: hidden;
  padding: 0!important;
  // box-shadow: 0px 0px 2px 0px variable.$box-shadow-color!important;
  box-shadow: variable.$primary-box-shadow!important;
  min-width: 300px;

  &.dropdown-panel-top-v2 {
    margin-top: -8px;
  }

  &.dropdown-panel-bottom-v2 {
    // margin-top: 8px;
    margin-top: 0px;
  }

  .p-menu-list {
    .p-menuitem {
      border-bottom: 1px solid variable.$bg-disabled-color;

      &.special-item {
        
        .icon-svg path {
          stroke: variable.$danger-color !important;
        }

        .p-menuitem-text {
          color: variable.$danger-color !important;
        }
      }

      .p-menuitem-link {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;

        .p-menuitem-text {
          font-weight: 400;
          font-size: variable.$text-default-size;
          font-family: "Poppins", sans-serif;
          color: variable.$color-gray;
        }

        .icon-svg {
          width: 24px;
          height: 24px;

          path {
            stroke: variable.$color-gray;
          }
        }
      }

      &:first-child {
        .p-menuitem-link {
          border-radius: 20px 20px 0 0;
        }
      }

      &:last-child {
        border-bottom: 0;
        .p-menuitem-link {
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }
}