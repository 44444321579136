@use '../../../../utils/variable.scss'as variable;

.p-inputgroup-container {
    .p-inputgroup-addon {
        max-height: 48px;
    }

    input { 
        border-top-left-radius: 6px!important;
        border-bottom-left-radius: 6px!important;
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: variable.$background-color-white-gray;
    color: variable.$primary-green-color !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    border-radius: 15px;
    height: 40px;

    &:hover { 
        border: 1px solid variable.$second-green-color !important;
        box-shadow: none !important;
    }
}

.wi-multi-select-dropdown{
    box-shadow: none !important;
    width: 50% !important;

    .p-multiselect-label{
        color: variable.$primary-green-color !important;
        width: 300px !important;
    }

    &:hover { 
        border: 1px solid variable.$second-green-color !important;
        box-shadow: none !important;
    }
}