@use "../../../utils/variable.scss" as variable;

:root {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
  }
  
  .tooltip-container-v2 {
    display: inline-block;
    position: relative;

    .tooltip-v2 {
        position: absolute;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        padding: 6px;
        color: var(--tooltip-text-color);
        background: var(--tooltip-background-color);
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        z-index: 100;
        white-space: nowrap;
      }

      .tooltip-v2::before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: var(--tooltip-arrow-size);
        margin-left: calc(var(--tooltip-arrow-size) * -1);
      }

      .tooltip-v2.top {
        top: calc(var(--tooltip-margin) * -1);
      }
    
      .tooltip-v2.top::before {
        top: 100%;
        border-top-color: var(--tooltip-background-color);
      }
      
      .tooltip-v2.right {
        left: calc(100%);
        top: 50%;
        transform: translateX(0) translateY(-50%);
      }
    
      .tooltip-v2.right::before {
        left: calc(var(--tooltip-arrow-size) * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: var(--tooltip-background-color);
      }
      
      .tooltip-v2.bottom {
        bottom: calc(var(--tooltip-margin) * -1.3);
      }

      .tooltip-v2.bottom::before {
        bottom: 100%;
        border-bottom-color: var(--tooltip-background-color);
      }
      
      .tooltip-v2.left {
        left: auto;
        right: calc(100% + var(--tooltip-margin));
        top: 50%;
        transform: translateX(0) translateY(-50%);
      }

      .tooltip-v2.left::before {
        left: auto;
        right: calc(var(--tooltip-arrow-size) * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: var(--tooltip-background-color);
      }
  }
  

  

  
  
  