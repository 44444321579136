@use "../../../../../utils/variable.scss";

$card-height: 80px;

.custom-style-list.p-datascroller .p-datascroller-list li {
  border-bottom: 1px solid variable.$gainsboro!important;
}

.media-list-item {
  font-size: 0.75rem;
  align-items: center;
  cursor: pointer;

  &.card-item-highlight {
    background-color: variable.$dark-sea-green;    
  }

  .card-image {
    border-radius: 12px;
    width: $card-height!important;
    height: $card-height;
    min-width: $card-height;
    min-height: $card-height;
    
    &.card-img-bg-svg,
    &.card-img-bg-jpg,
    &.card-img-bg-jpeg,
    &.card-img-bg-png {
      border: 1px solid variable.$gainsboro;
      background: variable.$gainsboro;
      object-fit: cover;
      object-position: center;
      // box-shadow: 3px 3px 5px variable.$gainsboro;
    }
  }
}
