@use "../../../../utils/variable.scss" as variable;

.editor-wrapper {
  // pointer-events: none;
  &.editor-disabled {
    pointer-events: none;
  }

  .ql-disabled .p-editor-content, .ql-disabled .ql-editor {
    background: variable.$background-color-white-smoke !important;
  }
}

.wi-form-richtext-editor-1line.p-editor-container  {
  .ql-disabled .p-editor-content, .ql-disabled .ql-editor {
    background: variable.$background-color-white-smoke !important;
  }

  // margin-bottom: 0.5rem;
  border-radius: 7px !important;

  .p-editor-toolbar {
    height: 47px;
    background: variable.$white;
    transition: height 1s;
    // border-bottom: none;
    box-shadow: 0 2px 2px -2px rgba(34, 47, 62, .1), 0 8px 8px -4px rgba(34, 47, 62, .07);
  }

  .ql-toolbar.ql-snow {
    padding: 4px 0 !important;

    .ql-fill, .ql-stroke {
      fill: #222f3e;
    }

    .ql-stroke {
      stroke: #222f3e;
      stroke-width: 1;
    }

    button {
      width: 34px;
      padding: 0;
      display: flex;
      justify-content: center;
      margin: 6px 1px 5px 0;

      &.ql-active {
        color: #000;
        background: #a6ccf7;
        border-radius: 3px;

        .ql-fill, .ql-stroke {
          fill: #000;
        }
    
        .ql-stroke {
          stroke: #000;
        }
      }
    }
  }

  .p-editor-content  .ql-editor {
    overflow: hidden !important;
    color: #000;
    font-size: 14px;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 47px !important;
  }

  .ql-formats {
    // margin: 6px 1px 5px 0;
    padding: 0 11px 0 12px;
  }
}
