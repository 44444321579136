@use "../../../../../../utils/variable.scss" as variable;
@import "../../../../../../utils/style/style-common.scss";

.header-subitem-tree.p-tree {
    border: none;
    padding: 0px;
    .p-tree-container {
        .p-treenode-droppoint.p-treenode-droppoint-active {
            background-color: variable.$pale-green;
        }

        .p-treenode {
            .p-treenode-content {
                padding: 0px;

                .p-tree-toggler {
                    padding: 0px;
                    display: none;

                    .p-tree-toggler-icon {
                        display: none;
                    }
                }
            }
        }
    }
}

.header-subitem-list-panel {
    width: 100%;
    margin: 10px 0px;

    &.p-panel.p-panel-toggleable {
        .disable-btn {
            color: variable.$color-error;
        }

        .p-panel-header {
            padding: 5px 20px 5px 10px;
            display: flex;
            border-radius: 24px;

            .p-panel-title {
                flex: 1;
                font-size: 13px;

                p {
                    margin: 0px;
                }
            }
        }
    }
}
