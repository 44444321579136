@use "../../utils/variable.scss" as c;
@import "../../utils/style/style-common.scss";

@mixin flexBox($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin tagStatus($color) {
  background: linear-gradient(to right, $color 40%, lighten($color, 15%));
  padding: 10px 23px;
  border-radius: 10px;
  color: c.$text-color-white;
  font-weight: 700;
  min-width: 120px;
  border-radius: 20px;
}

@mixin buttonCoupon($color) {
  background: $color;
  padding: 0.25rem 2.5rem;
  border: 1px solid $color;

  &:focus {
    box-shadow: none;
  }
}

@mixin card() {
  height: 105px;
  width: 250px;
  text-align: center;
  background: c.$white;
  color: c.$text-color-gray;
  border-radius: 30px;
  margin: 15px 15px 0 0;
  font-size: 18px;
}

.coupon-detail-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .map-container {
    width: 100%;
    height: 400px;
  }

  .wi-tag {
    @extend .wi-tag-custome;

    &.tag-status-active {
      background: #6bcdc1;
    }
    &.tag-status-inactive {
      background: #ababab;
    }

    &.tag-status-redeemed {
      background: #83c443;
    }
    &.tag-status-expired {
      background: #980035;
    }
    &.tag-status-invalid {
      background: #333333;
    }
  }
  .header-containers {

    .loading-component {
      height: 400px;
    }
    
    .show-map-container {
      width: 100%;
      .btn-show-map {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .show-map-button {
          width: 130px;
          font-size: 14px;
          font-weight: 700;
          padding: 0 10px;
          height: 30px;
          align-self: center;
          border-radius: 20px;
          color: c.$second-green-color;
          background-color: c.$white;
          border: 1px solid c.$second-green-color;
          outline: none;
          box-shadow: none;
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          .p-button-label {
            font-weight: 700;
            font-family: c.$font-name-Sora;
          }
  
          .p-button-icon {
            font-size: 14px;
            font-weight: 600;
          }
  
          &:focus,
          &:hover {
            box-shadow: none;
          }
        }

        .show-map-button:hover {
          background: c.$primary-green-color;
          color: c.$white;
        }
      }
    }

    .code-action-buton {
      display: flex;
      flex-direction: row;
    }
    .code-action-buton:hover {
      background: c.$primary-green-color;
      color: c.$white;
    }
    .code-action-buton {
      width: 90px;
      font-size: 14px;
      font-weight: 700;
      font-family: "Sora" !important;
      padding: 0 10px;
      height: 29px;
      align-self: center;
      border-radius: 20px;
      color: c.$second-green-color;
      background-color: c.$white;
      border: 1px solid c.$second-green-color;
      outline: none;
      box-shadow: none;
      margin: 4px 0px 0px 4px;
      .p-button-label {
        font-weight: 700;
        font-family: c.$font-name-Sora;
      }
      .p-button-icon {
        font-size: 14px;
        margin-top: 2px;
      }

      &:focus,
      &:hover {
        box-shadow: none;
      }
    }
    .header-info{
      min-height: 120px;
      .title-container{
          margin-bottom: 10px;
          h3{
              text-align: left;
              font-weight: 700;
              font-family: "Sora";
          }
      }
      .content-container{
          margin-bottom: 10px;
          // margin-left: -5px;
          display: flex;
          font-size: 12px;
          font-weight: 700;
          .header-info-section{
              padding: 0 8px;
              text-align: left;
              height: fit-content;
              border-right: 1px solid #dee2e6;
              height: 100%;
              min-width: 80px;
              flex: 1;
              min-height: 36px;

              &:first-child {
                padding-left: 0;
              }

              .info {
                margin: 0;
                font-family: "Sora";
              }
              .green-color{
                  color: c.$second-green-color;
                  margin: 0;
              }

              a{
                  cursor: pointer;
              }

              &.header-info-section-area, &.header-info-section-partner {
                flex: 2;
              }

              &.header-info-section-date {
                flex: 2;
              }

              &.header-info-section-status {
                min-width: 120px;
              }
          }
          :last-child{
            padding-right: 0;
            border-right: none;
          }
      }
      .progress-bar{
          margin-bottom: 10px;
          border-radius: 20px;

          .p-progressbar {
            background: c.$coupon-progressbar-determinate-background;
          }
      }
  }
  }
  .content-header {
    .header-info {
      @include flexBox(normal, center);
      color: c.$text-color-gray;
      text-align: left;
      background-color: c.$second-green-color;
      padding: 20px 10px;
      border-radius: 20px;
      transition: all 0.5s;

      .header-info-section {
        margin-right: 30px;

        .info {
          color: c.$text-color-white;
          padding: 5px 15px;
          border-radius: 10px;
          font-family: "Sora";
          min-width: 275px;
        }
      }
    }

    .header-report {
      &-status {
        @include flexBox(space-between, center);
        flex-flow: row wrap;
        margin-top: 15px;

        .report-card-status {
          @include flexBox(stretch, center);
          @include card();
          width: 15%;
          color: c.$white;
          cursor: pointer;

          &.card-new {
            background: linear-gradient(
              to right,
              c.$coupon-new 40%,
              lighten(c.$coupon-new, 15%)
            );
          }

          &.card-expired {
            // background: linear-gradient(to right, #7629d7 40%, lighten(#7629d7 , 15% ));
            background: linear-gradient(
              to right,
              c.$coupon-expired 40%,
              lighten(c.$coupon-expired, 20%)
            );
          }

          &.card-active {
            background: linear-gradient(
              to right,
              c.$coupon-active 40%,
              lighten(c.$coupon-active, 20%)
            );
            // background: linear-gradient(to right, #2ed8b6, #59e0c5);
          }

          &.card-deleted {
            background: linear-gradient(to right, c.$coupon-deleted, #ff869a);
          }

          &.card-blocked {
            background: linear-gradient(
              to right,
              c.$coupon-blocked 40%,
              lighten(c.$coupon-blocked, 15%)
            );
          }

          &.card-used {
            background: linear-gradient(
              to right,
              c.$coupon-used 40%,
              lighten(c.$coupon-used, 20%)
            );
          }

          h2,
          .h2 {
            font-size: 1.5rem;
          }

          .icon {
            width: 60px;
            height: 60px;
            background: c.$background-color-white;
            margin: 0 19px;
            border-radius: 50%;
            @include flexBox(center, center);

            &-new {
              color: c.$coupon-new;
            }

            &-expired {
              color: c.$coupon-expired;
            }

            &-active {
              color: c.$coupon-active;
            }

            &-deleted {
              color: c.$coupon-deleted;
            }

            &-blocked {
              color: c.$coupon-blocked;
            }

            &-used {
              color: c.$coupon-used;
            }
          }
        }
      }

      &-land {
        @include flexBox(space-between, center);
        flex-flow: row wrap;
        margin-top: 35px;

        .report-card-land {
          @include card();
          border: 1px c.$black solid;
          box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
            0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%),
            0 2px 1px -1px rgb(0 0 0 / 20%);

          .total-report {
            margin-top: 15px;
          }
        }
      }

      .title-report {
        margin-bottom: 0;
      }

      .total-report {
        margin: 0;
        font-weight: 700;
      }
    }
  }

  .content-table {
    .table-code {
      padding: 0;
      margin: 10px 0 0 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      // height: calc(100vh - 270px);
      // height: calc(100vh - 220px);

      @extend .table-common;
      @extend .table-common-extra;

      // .p-datatable .p-datatable-wrapper {
        // height: calc(100vh - 270px) !important;
        // max-height: calc(100vh - 270px) !important;
      // }
      .p-datatable .p-datatable-wrapper {
        // height: calc(100vh - 220px) !important;
        // max-height: calc(100vh - 220px) !important;
        .p-selection-column {
          .p-checkbox .p-checkbox-box:not(.p-highlight) {
            border-color: c.$color-darkgray;
          }
        }
      }

      .p-datatable .p-datatable-wrapper .p-datatable-table tr {
        background-color: c.$white !important;
        border-bottom: none!important;

        td {
          border-bottom: #ebeef1 1px solid;
          &:last-child {
            background-color: c.$white !important;
          }
        }
      }

      .p-datatable .p-datatable-wrapper .p-datatable-table tr:hover {
        td {
          background-color: c.$primary-gray !important;
        }
      }

      .coupon-progress-bar-field {
        position: relative;

        .p-progressbar-determinate {
          height: 1.25rem;
          background: c.$coupon-progressbar-determinate-background;
          border-radius: 20px;

          .p-progressbar-label {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .label-value {
              width: 100%;
              position: absolute;
              top: 5px;
              left: auto;
              right: auto;
              line-height: 1;
              font-size: 12px;
            }
          }
        }

        .p-progressbar-value-animate {
          background: c.$coupon-progressbar-animation-background !important;
        }
      }

      .btn-serial {
        color: c.$light-green-color;
        font-weight: 700;

        &:hover {
          color: c.$light-green-color;
          font-weight: 700;
        }
      }
    }

    &__header {
      @include flexBox(space-between, flex-end);
      text-align: left;

      &.batch-panel {
        justify-content: normal;
      }

      .update-status,
      .update-land {
        display: flex;
        flex-direction: column;
        margin-right: 30px;

        .p-dropdown .p-dropdown-label {
          padding: 7px 0.75rem;
          padding-right: 5.25rem;
          width: 180px;
        }
        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: none;
          border-color: c.$pattent-blue;
        }
      }

      .search-coupon {
        &-input {
          width: 235px;

          &:focus {
            box-shadow: none;
            border-color: c.$pattent-blue;
          }
        }
      }

      p {
        margin-bottom: 0.5rem;
      }

      .p-dropdown .p-dropdown-label {
        padding-right: 5.25rem;
      }

      .btn-update {
        display: block;
        @include buttonCoupon(c.$second-green-color);
        margin-left: 10px;
        width: 230px;
      }
    }

    .toolbar-export {
      background: transparent;
      border: none;
      padding: 0;

      .p-button-help {
        @include buttonCoupon(c.$second-green-color);
      }
    }
  }
}

.p-menuitem-containers-red-style{
  .p-menuitem-text.headline {
    color: #c91728 !important;
  }

  .p-menuitem-text.description {
    color: #b1494d !important;
  }
}

.code-menuitem-disable {
  pointer-events: none;
  // background-color: #f2f1ed;
  .p-menuitem-icon {
    color: #d6d6d4 !important;
  }

  .p-menuitem-text.headline {
    color: #d6d6d4 !important;
  }

  .p-menuitem-text.description {
    color: #d6d6d4 !important;
  }
}

@media screen and (max-width: 1600px) {
  .coupon-detail-content
    .content-header
    .header-report-status
    .report-card-status {
    width: 23%;

    h2,
    .h2 {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 1240px) {
  .coupon-detail-content
    .content-header
    .header-report-status
    .report-card-status {
    width: 28%;

    h2,
    .h2 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 960px) {
  .coupon-detail-content
    .content-header
    .header-report-status
    .report-card-status {
    width: 45%;

    h2,
    .h2 {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 898px) {
  .coupon-detail-content .content-table__header .btn-search {
    width: 133px;
    height: 55px;
  }
}

@media screen and (max-width: 480px) {
  .coupon-detail-content
    .content-header
    .header-report-status
    .report-card-status {
    width: 100%;
  }
}
