@use "../../../utils/variable.scss" as variable;

.wi-toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variable.$white;
    border-radius: 20px;
    height: variable.$primary-element-height;
    min-width: 190px;

    .label-toggle {
      font-size: variable.$text-default-size;
      font-weight: 500 !important;
      line-height: 20px;
      font-family: 'Poppins', sans-serif;
      color: variable.$color-gray;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 54px;
        height: 32px;
        input { 
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: variable.$color-toggle-dis;
          -webkit-transition: .4s;
          transition: .4s;

          &::before {
            position: absolute;
            content: "";
            height: 28px;
            width: 28px;
            left: 3px;
            bottom: 2px;
            background-color: variable.$white;
            -webkit-transition: .4s;
            transition: .4s;
          }

          &.round {
            border-radius: 16px;
            &::before {
              border-radius: 50%;
            }
          }

          &.dis-slider {
            background: variable.$background-toggle-dis;
            cursor: auto;
          }
        }
      }
      
      input:checked + .slider {
        background-color: variable.$primary-color;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px variable.$primary-color;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
}