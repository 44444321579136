@use "../../utils/variable.scss" as variable;

h1.h1 {
  font-size: variable.$text-h1-size;
  font-weight: 500;
}

h2.h2 {
  font-size: variable.$text-h2-size;
  font-weight: 500;
}

h3.h3 {
  font-size: variable.$text-h3-size;
  font-weight: 500;
}

h4.h4 {
  font-size: variable.$text-h4-size;
  font-weight: 500;
}

h5.h5 {
  font-size: variable.$text-h5-size;
  font-weight: 500;
  line-height: 24px;
}

$spacers: (
  0,
  4,
  6,
  8,
  10,
  12,
  16,
  20,
  24,
  28,
  32,
  40,
  48,
  60
) !default;

@each $key in $spacers {
  // generate m-* classes
  .m-#{$key} {
    margin: #{$key}px !important;
  }

  .mt-#{$key} {
    margin-top: #{$key}px !important;
  }
  .mb-#{$key} {
    margin-bottom: #{$key}px !important;
  }
  .ml-#{$key} {
    margin-left: #{$key}px !important;
  }
  .mr-#{$key} {
    margin-right: #{$key}px !important;
  }

  .p-#{$key} {
    padding: #{$key}px !important;
  }

  .pt-#{$key} {
    padding-top: #{$key}px !important;
  }
  .pb-#{$key} {
    padding-bottom: #{$key}px !important;
  }
  .pl-#{$key} {
    padding-left: #{$key}px !important;
  }
  .pr-#{$key} {
    padding-right: #{$key}px !important;
  }

  .gap-#{$key} {
    gap: #{$key}px !important;
  }
}

.headline-common {
  display: flex;
  justify-content: flex-start;
}

.row-common {
  .element-form {

    .element-full {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: 700;
      color: variable.$color-time;
    }

    .p-selectbutton.wi-selectbutton {
      width: 100%;
      background-color: variable.$background-header;
      border: 0;
      border-radius: 24px;
      padding: 1px 4px !important;
      height: 48px !important;
      .p-button {
        background-color: transparent;
        padding: 8px 16px !important;
        border-radius: 24px !important; 
        border: 0;
        height: 40px;
        .p-button-label {
          font-family: 'Poppins', sans-serif;
          color: variable.$color-gray;
        }

        svg path, .p-button-label, .label {
          color: variable.$color-gray;
          stroke: variable.$color-gray;
          font-weight: 400;
        }

        &.p-highlight {
          background-color: variable.$primary-color !important;
          border: 0 !important;
          svg path, .p-button-label, .label {
            color: variable.$white;
            stroke: variable.$white;
            font-weight: 500;
          }
        }
      }

      .p-button:not(.p-disabled):not(.p-highlight):hover {
        background-color: variable.$primary-color;
            svg path, .p-button-label, .label {
                stroke: variable.$white;
                color: variable.$white;
            }
      }
    }

    .label {
        display: flex;
        color: variable.$black;
        position: relative;
        font-weight: 700;
        font-size: variable.$text-default-size;
        align-items: flex-end;
        font-family: 'Poppins', sans-serif;
  
        .required-label {
          color: variable.$red-error;
        }

        .icon-info {
          display: none;
        }
      }

      .p-button-label {
        font-family: 'Poppins', sans-serif;
      }

      .p-inputnumber {
        width: 100%;
      }

      textarea {
        border: 1px solid variable.$background-color-white-gray;
        color: variable.$black-gray-color;
        border-radius: 16px;
        padding: 8px 16px;
        width: 100%;
        background: variable.$background-color-white;
        text-align: left;
        position: relative;
        min-height: 144px;
        font-size: variable.$text-default-size;

        &:focus-visible {
          outline: none;
          border: 1px solid variable.$second-green-color !important;
        }
      }

      .p-dropdown-filter {
        margin: 0;
        border-radius: 20px !important;
        height: variable.$primary-element-height;
      }
  
      .p-inputtext, .p-dropdown {
        width: 100%;
        height: 48px;
        border-radius: 16px;
        font-size: variable.$text-default-size;
        font-family: 'Poppins', sans-serif;
        box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
        border: none;
  
        &:focus, &:hover {
            border: 0 !important;
            border-color: variable.$primary-color !important;
            box-shadow: 0px 0px 2px 0px variable.$primary-color;
        }

        &.p-disabled {
          background-color: variable.$bg-disabled-color;
          box-shadow: none;

          .p-dropdown-trigger-icon {
            color: variable.$icon-disabled !important;
          }
        }

        &:focus-visible {
          border: 0 !important;
        }

        &:focus-visible {
          border: 0 !important;
        }
      }

      .p-dropdown {
        border: 0 !important;
        .p-inputtext {
          box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
          text-align: left;
          font-size: variable.$text-default-size;
          font-family: 'Poppins', sans-serif;
          color: variable.$black;
          div {
            font-size: variable.$text-default-size;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
          }
          &:focus, &:hover {
            box-shadow: 0px 0px 2px 0px variable.$primary-color !important;
          }
          
          &.p-dropdown-label {
            box-shadow: unset !important;  
          }
        }
      }
  
      .error {
        position: relative;
        color: variable.$red-error;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1.15;
        font-size: variable.$text-min-size;
        font-weight: 400;
        padding: 10px 0px 0px 24px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        display: flex;
  
        &::before {
          display: block; 
          content: url(../../assets/images/icons/errorIcon.svg);
          position: absolute;
          left: 0px;
          top: 8px;
          width: 18px;
          height: 18px;
        }
      }
  }
}

.avatar-user {
  .user-column {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    .user-image {
      .user-avatar {
        width: 72px;
        height: 72px;
        position: relative;

        .user-avatar-default {
          height: 100%;
          border-radius: 36px;
          background-color: variable.$background-avatar;
          display: flex;
          align-items: center;
          justify-content: center;
          color: variable.$text-color-white;
          font-weight: 500;
          font-size: variable.$text-h2-size;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          // padding: 2px;
        }

        .user-role {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .user-info {
      width: 100%;
      .user-name {
        font-weight: 400;
        font-size: variable.$text-default-size;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        width: 100%;
      }

      .user-email {
        font-weight: 400;
        font-size: variable.$text-min-size;
        color: variable.$color-time;
      }
    }
  }
}

.partner-item-common {
  .partner-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .partner-name {
      .name {
        font-size: variable.$text-default-size;
        font-weight: 400;
        color: variable.$dark-gray;
        font-family: 'Poppins', sans-serif;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 220px;
        overflow: hidden;
      }
    }
    .partner-picture {
      width: variable.$primary-element-height;
      height: variable.$primary-element-height;
      background-color: variable.$background-disabled;
      border-radius: 10px;
      overflow: hidden;
      // box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        padding: 5px;
      }
    }
  }
}

.icon-circle {
  max-height: 16.67px;
  height: 16.67px;
  min-width: 16.67px;
  width: 16.67px;
  border-radius: 20px;
  margin: 3.33px;

  &.icon-circle-big {
    height: 20px;
    width: 20px;
    margin: 0px;
    max-height: 20px;
  }

  &.wi-active {
    background: variable.$color-light-green;
  }

  &.wi-inactive {
    background: variable.$color-orange;
  }

  &.wi-archive {
    background: variable.$color-gray;
  }

  &.wi-complete {
    background: variable.$color-teal;
  }
}

@import './button'; 