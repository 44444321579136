$button-height: 40px;
$button-gap: 16px;
$button-padding: 10px;
$transition-time: 0.3s;

.wi-common-button, .wi-common-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: $button-gap;
  min-width: 130px;
  height: $button-height;
  color: variable.$white;
  font-weight: 500;
  font-size: variable.$text-default-size;
  text-decoration: none;
  padding: $button-padding 24px;
  border-radius: 24px;
  box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
  outline: none;
  transition: background-color $transition-time, color 0s, border-color $transition-time, box-shadow $transition-time !important;

  .p-button-label, .wi-btn-label {
    // color: variable.$white;
    transition: background-color $transition-time, color 0s, border-color $transition-time, box-shadow $transition-time !important;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
    gap: $button-gap;
  }

  .icon-svg path {
    stroke: variable.$white;
    transition: stroke 0s ease-in-out;
  }

  .p-button-icon {
    font-weight: 500;
    font-size: variable.$text-h3-size;
    margin: 0; 
  }

  .icon-svg {
    min-width: calc($button-height / 2);
    width: calc($button-height / 2);
    height: calc($button-height / 2);
  }

  &.wi-button-medium {
    padding: 5px 20px;
    height: 35px;
  }

  &:focus, &:hover {
    box-shadow: none;
  }

  &.p-disabled {
    background-color: variable.$background-header !important;
    border-color: variable.$background-header !important;
    color: variable.$color-disabled !important;

    .icon-svg path {
      stroke: variable.$color-disabled;
    }
  }
}

.wi-common-button-icon {
  @extend .wi-common-button;

  min-width: $button-height;
  max-width: $button-height;
  width: $button-height;
  padding: $button-padding;
}

.wi-button-icon-left {
  flex-direction: row-reverse;
}

.p-button.wi-secondary-button-v2, .wi-secondary-button-v2 {
  @extend .wi-common-button;

  border: 0;
  background-color: variable.$secondary-bg-color;
  border-color: variable.$secondary-bg-color;
  color: variable.$secondary-btn-text-color;
  box-shadow: none;
  // width: 100%;

  .icon-svg path {
    stroke: variable.$secondary-btn-text-color;
  }

  &:enabled, &:not(.p-disabled) {
    &:hover {
      background-color: variable.$secondary-bg-hover-color;
      border-color: variable.$secondary-bg-hover-color;
      color: variable.$secondary-btn-text-hover-color;
      box-shadow: none;

      .icon-svg path {
        stroke: variable.$secondary-btn-text-hover-color;
      }
    }
  }

  &.p-disabled {
    background-color: variable.$background-header !important;
    border-color: variable.$background-header !important;
    color: variable.$color-disabled !important;

    .icon-svg path {
      stroke: variable.$color-disabled;
    }
  }
}

.p-button.wi-primary-button-v2, .wi-primary-button-v2 {
  @extend .wi-common-button;

  background-color: variable.$primary-bg-color;
  border-color: variable.$primary-bg-color;
  color: variable.$primary-btn-text-color;
  box-shadow: none;
  // width: 100%;

  .icon-svg path {
    stroke: variable.$primary-btn-text-color;
  }

  &:enabled, &:not(.p-disabled) {
    &:hover {
      background-color: variable.$primary-bg-hover-color;
      border-color: variable.$primary-bg-hover-color;
      color: variable.$primary-btn-text-hover-color;
      box-shadow: none;

      .icon-svg path {
        stroke: variable.$primary-btn-text-hover-color;
      }
    }
  }

  &.p-disabled {
    background-color: variable.$background-header !important;
    border-color: variable.$background-header !important;
    color: variable.$color-disabled !important;

    .icon-svg path {
      stroke: variable.$color-disabled;
    }
  }
}

.p-button.wi-white-button-v2, .wi-white-button-v2 {
  @extend .wi-common-button;

  background-color: variable.$white;
  border: 1px solid variable.$white;
  color: variable.$color-gray;
  box-shadow: none;
  

  .icon-svg path {
    stroke: variable.$color-gray;
  }

  &:enabled, &:not(.p-disabled) {
    &:hover {
      background: variable.$color-light-blue;
      border-color: variable.$color-light-blue;
      color: variable.$white;
      box-shadow: none;

      .icon-svg path {
        stroke: variable.$white;
      }
    }
  }

  &.p-disabled {
    background-color: variable.$white !important;
    border-color: variable.$white !important;
    color: variable.$color-disabled !important;

    .icon-svg path {
      stroke: variable.$color-disabled;
    }
  }
}

.p-button.wi-danger-button-v2, .wi-danger-button-v2 {
  @extend .wi-common-button;

  border: none;
  background-color: variable.$danger-bg-color;
  border-color: variable.$danger-bg-color;
  color: variable.$danger-btn-text-color;
  box-shadow: none;
  // width: 100%;
  
  .icon-svg path {
    stroke: variable.$danger-btn-text-color;
  }

  &:enabled, &:not(.p-disabled) {
    &:hover {
      background: variable.$danger-bg-hover-color;
      border-color: variable.$danger-bg-hover-color;
      color: variable.$danger-btn-text-hover-color;
      box-shadow: none;

      .icon-svg {
        path {
          stroke: variable.$danger-btn-text-hover-color;
        }
      }
    }
  }
}

.p-button.wi-primary-danger-button-v2, .wi-primary-danger-button-v2 {
  @extend .wi-common-button;

  border: none;
  background-color: variable.$danger-btn-text-color;
  border-color: variable.$danger-btn-text-color;
  color: variable.$white;
  box-shadow: none;

  .icon-svg path {
    stroke: variable.$white;
  }

  &:enabled, &:not(.p-disabled) {
    &:hover {
      background: variable.$danger-btn-text-hover-color;
      border-color: variable.$danger-btn-text-hover-color;
      color: variable.$white;
      box-shadow: none;

      .icon-svg {
        path {
          stroke: variable.$white;
        }
      }
    }
  }
}

.p-button.wi-primary-button-icon-v2, .wi-primary-button-icon-v2 {
  @extend .wi-common-button-icon;
  @extend .wi-secondary-button-v2;
}

.p-button.wi-danger-button-icon-v2, .wi-danger-button-icon-v2 {
  @extend .wi-common-button-icon;
  @extend .wi-danger-button-v2;
}

.p-button.h48 {
  height: 48px;
}