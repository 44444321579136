@use '../../../utils/variable.scss' as variable;
@import '../../../utils/style/style-common.scss';

.footers-manager {
    flex-direction: column;
    display: flex;
    position: relative;

    .manager-container {
        width: 100%;

        .certificate {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .headline {
                @extend .headline-common;
            }

            .certificate-action {
                width: 100%;
            }
        }

        .search-box {
            @extend.search-box-common;
        }

        .table-footers {
            @extend .table-common;
            @extend .table-common-extra;
            @extend .table-global-content;
            height: calc(100vh - 120px);
        }
    }
}