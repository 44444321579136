@use "../../../../utils/variable.scss" as variable;

.editor-wrapper {
  &.editor-disabled {
    pointer-events: none;
  }

  .ql-disabled .p-editor-content, .ql-disabled .ql-editor {
    background: variable.$background-color-white-smoke !important;
  }
}

.wi-form-richtext-editor {
  height: auto;
  border-radius: 7px !important;

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 100% !important;
    min-height: 100px;
  }

  .ql-disabled .p-editor-content  {
    background: variable.$background-color-white-smoke !important;
  }
  .ql-tooltip {
    left: 5px !important;
  }
  
  .ql-tooltip.ql-editing a.ql-action::after {
    content: "Insert";
  }
  .ql-tooltip[data-mode="link"]::before {
    content: "Insert link:";
  }
  .ql-tooltip input[type="text"] {
    width: 255px;
  }
}