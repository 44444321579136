@use "../../../../utils/variable.scss" as variable;

.change-log {
    .change-log-container {
        .log-list {
            height: 88vh;
            min-height: 85vh;
            overflow: auto;
        }
        .log-item {
            border-bottom: 1px solid variable.$background-color-sidebar;
            color: variable.$black;

            .log-time {
                font-family: 'Poppins', sans-serif;
            }

            .user-update {
                display: flex;
                justify-content: space-between;

                .label {
                    font-weight: 500;
                    font-family: 'Poppins', sans-serif;

                }

                .user-info {
                    display: flex;
                    justify-content: space-between;
                    .user-name {
                        font-weight: 500;
                        color: variable.$color-time;
                        font-family: 'Poppins', sans-serif;

                    }
                }
            }
        }

        .submit-log {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
        }
    }
}