.p-dropdown.dropdown-component-v2 {
  .p-dropdown-label {
    color: variable.$black;
  }
  .p-dropdown-trigger {
    .p-dropdown-trigger-icon {
      color: variable.$black !important;
    }
  }
}

.p-dropdown-panel.dropdown-panel-v2 {
  border-radius: 20px;
  // box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
  box-shadow: variable.$primary-box-shadow!important;

  .p-dropdown-items-wrapper {
    // min-height: 350px;
    max-height: 350px!important;
  }

  &.dropdown-panel-top-v2 {
    margin-top: -8px;
  }

  &.dropdown-panel-bottom-v2 {
    // margin-top: 8px;
    margin-top: 0px;
  }

  .p-dropdown-header {
    background-color: variable.$white;
    border-bottom: 0;
    padding: 16px 16px 0 16px;
    border-radius: 20px 20px 0 0;

    .p-inputtext {
      background-color: variable.$white;
      border-radius: 20px;
      height: variable.$primary-element-height;
      border: 0;
      font-family: "Poppins", sans-serif;
      font-size: variable.$text-default-size;
      padding: 8px 40px 8px 16px;
      box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;

      &:focus, &:hover {
        box-shadow: 0px 0px 2px 0px variable.$primary-color !important;
      }
    }

    .p-dropdown-filter {
      margin: 0;
      border-radius: 20px !important;
      height: variable.$primary-element-height !important;
    }

    .p-dropdown-filter-icon {
      font-weight: 700;
      height: calc($icon-height);
      width: calc($icon-height);
      background-color: variable.$primary-color;
      border-radius: $border-radius;

      background-image: url("../../../assets/images/icons/search.svg");
      background-repeat: no-repeat;
      background-position: center center;

      top: 32% !important;
      right: 4px !important;

      &::before {
        font-size: variable.$text-icon-size;
        color: variable.$white;
        position: absolute;
        bottom: $icon-padding * 2;
        right: $icon-padding * 2;
        content: unset !important;
      }
    }
  }
  .p-dropdown-items {
    padding: 16px;

    .p-dropdown-empty-message {
      font-size: variable.$text-default-size;
      font-family: "Poppins", sans-serif;
      color: variable.$dark-gray;
      padding: 8px;
    }
    .p-dropdown-item {
      font-size: variable.$text-default-size;
      padding: 16px 8px;
      border-bottom: 1px solid variable.$bg-disabled-color;
      
      @extend .partner-item-common;
      div {
        font-size: variable.$text-default-size;
        font-family: "Poppins", sans-serif;
        color: variable.$dark-gray;
      }

      .wi-language-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wi-language-label {
          font-weight: 400;
          font-size: variable.$text-default-size;
          font-family: "Poppins", sans-serif;
        }
        .wi-language-icon {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      &.p-highlight {
        background-color: variable.$background-header !important;
      }
    }
  }
}

.p-dropdown-panel.wi-dropdown-panel {
  border-radius: 20px;
  // border: 1px solid variable.$background-header;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

  &.dropdown-panel-top-v2 {
    margin-top: -8px;
  }

  &.dropdown-panel-bottom-v2 {
    // margin-top: 8px;
    margin-top: 0px;
  }

  .p-dropdown-items {
    padding: 0 !important;
    .p-dropdown-item {
      width: 100%;
      padding: 12px 16px;
      height: 48px;
      border-bottom: 1px solid variable.$background-header;

      &:first-child {
        border-radius: 20px 20px 0 0;
      }

      &:last-child {
        border-radius: 0 0 20px 20px;
        border-bottom: 0;
      }
    }
  }
}

.p-overlaypanel.overlaypanel-filter-v2 {
  z-index: 9999 !important;
  border-radius: 20px;
  min-width: 352px;
  width: 352px;
  margin-top: 0 !important;
  // box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
  box-shadow: variable.$primary-box-shadow!important;

  .p-overlaypanel-content {
    padding: variable.$text-h5-size;
    .p-listbox-header {
      border-bottom: 0;
      padding: 0;
      margin-bottom: 16px;
      
      .p-inputtext {
        background-color: variable.$white;
        border-radius: 20px;
        height: variable.$primary-element-height;
        border: 0;
        font-family: "Poppins", sans-serif;
        font-size: variable.$text-default-size;
        padding: 8px 40px 8px 16px;
        box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;

        &:focus, &:hover {
          box-shadow: 0px 0px 2px 0px variable.$primary-color !important;
        }
      }

      .p-listbox-filter-icon {
        font-weight: 700;
        height: calc($icon-height);
        width: calc($icon-height);
        background-color: variable.$primary-color;
        border-radius: $border-radius;

        top: 32% !important;
        right: 4px !important;

        background-image: url("../../../assets/images/icons/search.svg");
        background-repeat: no-repeat;
        background-position: center center;

        &::before {
          font-size: variable.$text-icon-size;
          color: variable.$white;
          position: absolute;
          bottom: $icon-padding * 2;
          right: $icon-padding * 2;
          content: unset !important;
        }
      }
    }

    .p-listbox-list-wrapper {
        // min-height: 350px;
        max-height: 350px!important;
    }

    .p-listbox-list {
      padding: 8px 0 0 0 !important;
      display: inline;
      
      .p-listbox-item {
        padding: 16px 8px !important;
        border-bottom: 1px solid variable.$background-header;

        &:last-child {
          border-bottom: 0;
        }

        .list-boxs {
          align-items: center;

          @extend .partner-item-common;
        }

        .p-checkbox-label {
          margin-left: 0 !important;
          font-weight: 400;
          font-size: variable.$text-default-size;
          color: variable.$dark-gray;
          font-family: "Poppins", sans-serif;
        }
      }
    }

    .p-checkbox {
      .p-checkbox-box {
        box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
        border-radius: 4px;
        background-color: variable.$white;
        border-color: transparent;

        &.p-focus {
          box-shadow: 0px 0px 2px 0px variable.$box-shadow-color!important;
          border-color: transparent!important;
        }

        &:hover {
          border-color: transparent;
          background-color: variable.$background-header;
        }

        &.p-highlight {
          background: variable.$primary-color;
          .p-checkbox-icon {
            &::before {
              font-size: 9px;
              display: flex;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
