@use "../../../../utils/variable.scss" as variable;

.wi-btn.p-button {
    font-size: 14px;
    font-weight: 700;
    margin: auto 5px;
    border: 1px solid variable.$white;
    border-radius: 20px;
    background: variable.$background-color-white;
    color: variable.$second-green-color;
    padding: 5px 15px;
    width: 125px;

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        background: variable.$second-yellow-color!important;
        color: variable.$second-green-color!important;
        border: 1px solid variable.$second-yellow-color!important;
    }

    &.wi-btn-published {
        background: variable.$second-yellow-color;
        border: 1px solid variable.$second-yellow-color!important;
        width: 100px;
        &:hover {
            background: variable.$second-yellow-color!important;
            color: variable.$second-green-color!important;
            border: 1px solid variable.$second-yellow-color!important;
        }
    }

    &.wi-btn-back {
        width: 145px;
    }
    
    .p-button-icon {
        font-size: 13px;
    }
}