@use "../../../../utils/variable.scss" as variable;

.coupon-detail-import-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .p-selectbutton {
    height: 48px;
    left: 0px;
    top: 0px;
    border: 1px solid variable.$primary-green-color;
    box-sizing: border-box;
    border-radius: 30px;
    position: relative;
    padding: 0 3px;
    

    .p-button {
      padding: 12px 20px;
      height: 40px;
      top: 3px;
      border: none;
      border-radius: 30px;
      animation: deselect_btn 60ms ease-in 1 reverse both;

      .p-button-label {
        font-size: 18px;
        font-family: Lato; 
        font-style: normal;
        color: variable.$primary-green-color;
      }

      &:focus {
        box-shadow: none;
      }

      &.p-highlight {
        border-color: variable.$second-green-color !important;
        background-color: variable.$second-yellow-color !important;
        border: 1px solid variable.$second-yellow-color !important;
        border-radius: 30px;
        animation: select_btn 220ms ease-out 1 normal both;
        background-image: linear-gradient(90deg, variable.$second-yellow-color, variable.$second-yellow-color);
        background-size: 0;
        background-repeat: no-repeat;

        &:hover {
          border-color: variable.$second-yellow-color;
          background-color: variable.$second-yellow-color;
        }
      }
    }
  }

  .import-btn {
    width: 40%;
    min-width: 300px!important;
    // padding: 20px;
    padding-top: 20px!important;
    border-radius: 20px;
     

    .input-upload {
      display: none;
    }

    .input-item {
      text-align: left;

      .p-selectbutton {
        .p-button {
          min-width: 100px;
          width: 50%;
        }
      }

      label {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        color: variable.$light-slate-gray;
        position: relative;
        padding-right: 8px;
        padding-bottom: 5px;

        .required-label {
          color: variable.$red-error;
        }
        .lowercase-label {
          text-transform: lowercase;
        }
      }

      .error {
        position: relative;
        color: variable.$red-error;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1.3;
        font-size: 12px;
        font-weight: 400;
        padding: 10px 0px 0px 24px;

        &::before {
          display: block;
          content: url(../../../../images/errorIcon.svg);
          position: absolute;
          left: 0px;
          top: 8px;
          width: 18px;
          height: 18px;
        }
      }
    }

    .upload-file {
      padding-top: 20px;
      flex-direction: column;

      .label-upload-csv {
        width: 100%;
        text-align: center;
        padding: 0.5rem;
        font-size: 0.9375rem;
        font-weight: 600;
        // padding-left: 30px;
        font-style: normal;
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
        border-radius: 20px;
        cursor: pointer;
      }

      .name-file {
        padding-left: 20px;
      }
    }

    .note-message {
      padding: 0;
      margin: 0.25rem 1rem;

      p {
        padding: 0;
        margin: 0;
        color: variable.$red-color;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}