@use "../../../utils/variable.scss" as variable;

.button-action {
  font-family: Sora;
  
  .fa-ellipsis {
    border-radius: 50px;
    padding: 7px 8px;
    font-size: 13px;

    &:hover {
      background: variable.$color-darkgray;
    }
  }
}

.button-action-menu {
  min-width: 250px;

  &.p-menu {
    .p-menuitem-text {
      font-size: 14px;
      margin-left: 5px;
    }
  }
}
