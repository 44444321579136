

.edit-basic-information {
    .p-calendar{
      display: flex !important;
    }

    .partner-logo-img {
      .label-upload-field-v2 {
        height: 264px;
        .media-preview {
          object-fit: contain !important;
        }
      }
    }
  }