@use "../../../../utils/variable.scss" as variable;
@import "../../../../components_v2/utils/style-common.scss";

.partner-form-container {
  padding: 24px 64px;
  overflow-y: auto;
  height: calc(100vh - 160px);
  @extend .row-common;

  .btn-submit-container {
    .btn-submit {
      background-color: transparent;
      border: 1px solid variable.$white;

      &:hover {
        border: 1px solid variable.$second-yellow-color;
        color: variable.$second-green-color;
      }
    }
  }

  .wi-form-toggle-button-partner.p-inputswitch {
    &.p-inputswitch-checked,
    &.p-inputswitch-checked:hover {
      .p-inputswitch-slider {
        background: variable.$second-green-color;
      }
    }
    margin-top: 5px;
  }
  
  .search-global .search-form-element input {
    height: 42px;
  }
}
