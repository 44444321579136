.wi-tabmenu-v2.p-tabmenu {
  .p-tabmenu-nav {
      border: none;

      .p-tabmenuitem {
          .p-menuitem-link:not(.p-disabled):focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: none;
          }

          .p-menuitem-link {
              margin: 0;
              padding: 8px 16px 12px 16px;
              height: variable.$primary-element-height;
              border: 0px 0px 1px 0px;
              width: max-content; // important

              .p-menuitem-text {
                  font-size: variable.$text-default-size;
              }
          }

          &.p-highlight .p-menuitem-link {
              border-color: variable.$primary-color;
              color: variable.$primary-color;
          }
      }
  }
}