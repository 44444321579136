@use '../../../../utils/variable.scss' as variable;

.wi-form-textarea {
  &.p-inputtextarea {
    padding: 8px 16px;
    min-height: 144px;
    width: 100%;
    text-align: left;
    color: variable.$black-gray-color;
    border-radius: 16px;
    border: 1px solid variable.$background-color-white-gray;
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus-visible {
      outline: none;
      box-shadow: none;
      border: 1px solid variable.$second-green-color;
    }

    &.has-error {
      border: 1px solid variable.$red-error;
    }
  }
}