@use '../../../../../../utils/variable.scss'as variable;
@import '../../../../../../utils/style/style-common.scss';

.change-donor-form {
    .form-container {
        @extend .form-content;

        .row-content {
            text-align: left;


            input, textarea {
                color: variable.$primary-green-color !important;
            }

            label {
                font-size: 14px !important;
                text-transform: unset !important;

                .required-label {
                    color: variable.$red-error;
                }

                .lowercase-label {
                    text-transform: lowercase;
                }
            }

            .p-selectbutton {
                width: 100%;
                .p-button {
                    width: 50%;
                }
            }


            .p-search {
                position: relative;
                .p-inputgroup-addon {
                    position: absolute;
                    left: 0;
                    z-index: 2;
                    border-top-right-radius: unset !important;
                    border-bottom-right-radius: unset !important;
                    border-right: unset;
                    border-top-left-radius: 1rem;
                    border-bottom-left-radius: 1rem;
                    height: 42px;
                    background-color: transparent;
                    border: none;
                }
                input {
                    padding-left: 3rem;
                    &:focus {
                        border: 1px solid variable.$background-color-white-gray !important;
                    }
                }
            }


            .btn-donor {
                position: absolute;
                right: 20px;
                bottom: 20px;
                width: 94%;
            }
        }
    }
}