@use "../../../../../utils/variable.scss" as variable;

$card-width: 260px;
$card-height: 200px;
$card-border-radius: 0px;
$color-highlight: rgba(variable.$primary-color, 0.1);
$file-name-height: 0px;
// $file-name-height: 38px;

.dummy-block {
  height: 1px;
  width: 184px;
  pointer-events: none;
}

.media-card-item {
  cursor: pointer;

  &.card-item-highlight {
    .card-image-backdrop {
      display: flex!important;
    }
  }

  .card-item {
    background-color: variable.$background-color-sidebar;
    border-radius: $card-border-radius;
    box-shadow: none;
    border: none;

    &:hover {
      background-color: $color-highlight;
    }

    .p-card-body {
      padding: 0px;
      position: relative;
      overflow: hidden;
      border-radius: $card-border-radius;

      .p-card-content {
        padding: 0;
        width: 100%;
        height: 100%;
        border-radius: $card-border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
  
        span {
          font-size: 12px;
          padding: 10px;
          word-break: break-word;
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(variable.$white, 0.8);
          box-shadow:
            0px -1.0420215129852295px 1.04px rgba(variable.$white, 0.1),
            0px 8.336172103881836px 6.25px rgba(variable.$black, 0.05);
          backdrop-filter: blur(70px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          max-height: $file-name-height;
          display: none;
        }

        .card-image-backdrop {
          position: absolute;
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 0;
          height: calc(100% - $file-name-height);
          width: 100%;
          background: rgba(variable.$white, 0.7);

          svg {
            margin-top: $file-name-height;
            fill: variable.$primary-color;
            height: 37px;
            width: 37px;

            path {
              stroke: rgba(variable.$white, 0);

              &:last-child {
                stroke: rgba(variable.$white, 1);
              }
            }
          }
        }
  
        .card-image-wrapper {
          width: 100%;
          overflow: hidden;
          // height: $card-height;
          // border-bottom: 1px solid variable.$gainsboro;
  
          .card-image {
            object-fit: cover;
            object-position: center;
            transition: transform 0.5s ease;
            aspect-ratio: 1;
            width: 100%;
            min-height: $card-height;
  
            &:hover {
              transform: scale(
                1.5
              ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
            }
  
            &.card-img-w-100 {
              width: 100%;
            }
  
            &.card-img-w-50 {
              object-fit: contain !important;
              padding-bottom: 60px;
              padding-top: 40px;
            }
  
            &.card-img-bg-jpg,
            &.card-img-bg-jpeg {
              object-fit: cover;
            }
  
            &.card-img-bg-png,
            &.card-img-non-cover {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
