@use "../../../utils/variable.scss" as variable;

.wi-tag-status {
    display: flex;
    align-items: center;
    border-radius: calc(variable.$primary-element-height / 2);
    height: variable.$primary-element-height;
    
    svg {
        width: 30px;
        height: 30px;
    }
}