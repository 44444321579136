.progress-spinner-v2 {
  .p-progress-spinner-svg {
    .p-progress-spinner-circle {
      stroke: variable.$second-green-color !important;
    }
  }
}

.loading-component-v2 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  
  .p-progress-spinner.progress-spinner-v2 {
    .p-progress-spinner-svg {
      .p-progress-spinner-circle {
        stroke: variable.$second-green-color !important;
      }
    }
  }
}

.loading-container-v2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(variable.$white, 1);

  &.content-loading-container-v2 {
    z-index: 2000;
  }

  &.bg-white {
    background: rgba(variable.$white, 1);
  }
  
  .p-progress-spinner.progress-spinner-v2 {
    .p-progress-spinner-svg {
      .p-progress-spinner-circle {
        stroke: variable.$second-green-color !important;
      }
    }
  }
}
