@use "../../../../utils/variable.scss" as variable;

.wrapper {
  width: 100%;
  margin: 0!important;
  flex: 1;
  transition: all .5s linear;

  &.screen-desktop {
    width: 1260px!important;
    &.screen-fullscreen {
      width: 100%!important;
    }
  }

  &.screen-tablet {
    width: 700px!important;
  }

  &.screen-mobile {
    width: 400px!important;
  }
  
  .draggable-component {
    width: calc(100%);
    max-width: calc(100%);
    padding: 0!important;
    margin: 0!important;
    margin-bottom: 2px;
  }

  .content {
    overflow: hidden;
    margin: auto;
    width: 100%;
    height: 100%;
    text-align: left;
    background: variable.$background-color-white-smoke;
  }
}
@media (min-width: 2000px) {
  .wrapper {
    &.screen-desktop {
      width: 100%!important;
    }
  }
}