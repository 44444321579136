@use "../../../utils/variable.scss" as variable;

$icon-size: 24px;

.sidebar-menu {
    background-color: variable.$white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2001;
    border-right: 1px solid variable.$background-header;
}

.sidebar-menu-content {
    width: 96px;
    transition: width 0.5s ease;
    position: relative;
    border-right: 1px solid variable.$background-color-sidebar;

    &:hover {
        width: 240px !important;
    }

    .sidebar-menu-container {
        display: flex;
        height: 100vh;
        flex-direction: column;

        .header-menu {
            display: flex;
            justify-content: center;
            align-items: center;

            .logo {
                width: 60px;
                height: 35px;
                .logo-menu {
                    background-position: bottom;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                }
                .logo-menu-prod{
                    background-image: url('../../../assets/images/logo/logo_bird.svg');
                }
                .logo-menu-none-prod{
                    background-image: url('../../../assets/images/logo/dev-logo-collapse.svg');
                }
            }
        }

        .content-menu {
            flex: 1;
            .p-menuitem {
                border: 1px solid variable.$white;

                .p-menuitem-link {
                    display: flex;
                    align-items: baseline;
                    text-decoration: none;
                    cursor: pointer;
                    padding: 10px;

                    .icon-content {
                        width: $icon-size;
                        min-width: $icon-size;
                        height: $icon-size;
                    }

                    .p-menuitem-icon {
                        width: 100%;
                        height: 100%;
                    }

                    .p-menuitem-text {
                        color: variable.$black;
                        overflow: hidden;
                        white-space: nowrap;
                        margin: auto 0;
                        flex: 1;
                        text-align: left;
                        font-weight: 500;
                    }
                }

                &:hover, &.active-menu {
                    background-color: rgba(variable.$primary-color, 0.1);
                    border-radius: 12px;
                }

                &.active-menu {
                    .p-menuitem-text {
                        color: variable.$primary-color;
                    }

                    svg path {
                        stroke: variable.$primary-color;
                    }
                }

                &.log-out {
                    .p-menuitem-text,
                    .p-menuitem-icon {
                        color: variable.$red-color;
                    }
                }

                .dropdown-menuitem {
                    padding-top: 107px !important;
                    position: absolute;
                    right: -267px;
                    border: 1px solid variable.$white;
                    top: 0;
                    bottom: 0;
                    background-color: variable.$white;
                    border-right: 1px solid variable.$background-color-sidebar;

                    .dropdown-menuitem-content {
                        height: 77vh;
                        min-height: 70vh;
                        overflow: auto;
                    }

                    &.show {
                        .p-menuitem {
                            .p-menuitem-text {
                                color: variable.$black;
    
                            }
        
                            svg path {
                                stroke: variable.$black;
                            }

                            &.active-menu {
                                .p-menuitem-text {
                                    color: variable.$primary-color;
                                }
            
                                svg path {
                                    stroke: variable.$primary-color;
                                }
                            }
                        }
                    }

                    .collapse-menu {
                        cursor: pointer;
                        .collapse-icon {
                            border-radius: 20px;
                            background-color: variable.$background-header;
                            svg {
                                font-size: variable.$text-h3-size;
                            }
                        }
                    }
                }
            }
        }

        .footer-menu {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;

            .footer-content {
                .name {
                    display: none;
                    transition: display 2s;
                }
            }
        }
    }
}

.sidebar-menu-content:hover .logo {
    width: 120px !important;
}
.sidebar-menu-content:hover .logo .logo-menu {
    width: 100%;
    height: 100%;
    // background-image: url('../../../assets/images/logo/logo_menu.svg') !important;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.sidebar-menu-content:hover .logo .logo-menu-prod{
    background-image: url('../../../assets/images/logo/logo_menu.svg') !important;
}
.sidebar-menu-content:hover .logo .logo-menu-none-prod{
   background-image: url('../../../assets/images/logo/dev-logo.svg') !important;
}

.sidebar-menu-content:hover .footer-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 12px;
    background-color: variable.$background-header;
}

.sidebar-menu-content:hover .footer-content .name {
    display: block !important;
    font-weight: 500;
    margin-right: 40px;
    transition: display 2s;
}

.sidebar-menu-content:hover .footer-content .avatar {
    margin-right: 40px;
    padding-left: 8px !important;
}