@use '../../../../../utils/variable.scss' as variable;

.header-item {
    border: 2px solid transparent;
    margin: -10px;
    padding: 10px;
    border-radius: 10px;

    &:hover {
        // border: 2px solid #0d99ff;
        background: variable.$light-gray;
    }

    &.header-item-active {
        border: 2px solid variable.$blue-outline;
        background: variable.$light-gray;
    }
}