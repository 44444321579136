@use '../../../../utils/variable.scss'as variable;

.action-form-land {
    border-bottom: 1px solid variable.$background-color-sidebar;
    .land-code {
      align-items: center;
      .code {
        font-weight: 700 !important;
      }
    }
}