@use "../../../utils/variable.scss" as variable;

.confirm-password-dialog {
  .p-dialog-header {
    padding: .5rem 1.5rem;
    width: 100%;
    background-color: variable.$second-green-color;
    color: variable.$text-color-white;
  }

  .p-dialog-content {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    padding-bottom: 20px !important;
    padding-top: 20px;

    .confirmation-password {    
        .password_filed {
            .p-inputtext {
                width: 100%;
                border-radius: 30px;
                height: 45px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: darkgray;
                }
            }
    
            label {
                font-weight: 700;
            }
        }

        .error {
          position: relative;
          color: variable.$red-error;
          letter-spacing: 1px;
          text-transform: uppercase;
          line-height: 1.3;
          font-size: 12px;
          font-weight: 400;
          padding: 10px 0px 0px 24px;

          &::before {
              display: block;
              content: url(../../../images/errorIcon.svg);
              position: absolute;
              left: 0px;
              top: 8px;
              width: 18px;
              height: 18px;
          }
        }
      }
      
      .progess_bar_container {
        padding: 30px 15px;
        .p-progressbar {
          height: 15px;
          border-radius: 10px;
        }
      }
  }   

  .p-dialog-header-icons .p-dialog-header-icon {
    color: variable.$text-color-white;
  }

  .p-dialog-footer {

    button {
      border: 0;
      outline: 0;
      box-shadow: none;
      
      &.button-hide {
        background-color: variable.$background-color-white;
        color: variable.$second-green-color;
  
        &:hover {
          background-color: variable.$background-color-white;
          color: variable.$second-green-color;
          
        }
      }

      &.button-confirm {
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
    
        &:hover {
            background-color: variable.$second-green-color;
          color: variable.$text-color-white;
        }
      }
    }
  }
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: variable.$font-name-Sora;
}