@use "../../../utils/variable.scss" as variable;

$border-radius: 20px;
$icon-height: 32px;
$icon-padding: 4px;

.wi-search-v2-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;

  .wi-search-icon {
    height: 32px;
    width: 32px;
    background-color: variable.$primary-color;
    border-radius: 20px;
    position: absolute;
    bottom: 4px;
    right: 4px;
    padding: 8px;

    svg {
      display: block;
    }
  }

  input.p-inputtext {
    width: 344px;
    height: calc($icon-height + $icon-padding * 2);
    color: variable.$color-time;
    background-color: variable.$white;
    border-radius: $border-radius;
    padding: 8px 16px;
    border: 0;
    font-size: variable.$text-default-size;
    box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;

    &:enabled {

      &:hover,
      &:focus {
        border-color: variable.$background-header;
        box-shadow: 0px 0px 2px 0px variable.$primary-color !important;
      }
    }
  }
}