@use "../../../../utils/variable.scss" as variable;
@import "../../../../components_v2/utils/style-common.scss";

.land-form {
  width: 100%;
  height: calc(100vh - 84px - 73px);
  overflow-y: auto;
  .land-details-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid variable.$background-color-sidebar;
  }
  .land-form-container {
    width: 552px;
    margin: auto;


    .row-content {
      @extend .row-common;
      .land-text {
        font-family: "Poppins", sans-serif;
        color: variable.$black;
        font-size: variable.$text-default-size;
        font-weight: 400;
        text-align: left;
        &.label {
            font-size: variable.$text-h2-size;
            font-weight: 700;
        }
    }
    }
  }
}
