@use "../../../utils/variable.scss" as variable;
.row-content {
  .preview-inclusive1-form  {
    background: variable.$background-color-white;
    display: block;
    margin: 0 auto;
    padding: 0.4in;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm variable.$black-opac-half;
    width: 21cm;
    height: 29.7cm;

    .pdf-certificate {
      margin: 0;
      padding: 0;
      position: "relative";

      .pdf-header {
        width: 100%;
        height: 294px;

       

        .pdf-header-section-center {
          margin: 0;
          width: 100%;
          pointer-events: none;
        }

        .pdf-header-section-right {
          margin-right: 0px;
          width: 100%;
          position: relative;
          height: 331px;
          pointer-events: none;
        }
      }

      .pdf-body {
        margin-top: 3rem;
        width: 100%;
        position: relative;

        .pdf-body-section-center {
          margin-top: 1rem;
          width: 100%;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;

          .geo-coordinate {
            color: variable.$default-color-text;
            font-family: "Sora";
            font-size: 20px;
            line-height: 100%;
            font-weight: 700;
            text-align: center;
          }

          .pdf-title {
            margin: 0;
            width: 100%;
            color: variable.$primary-green-color;
            font-family: "Sora";
            font-size: 38px;
            letter-spacing: 1.2px;
            line-height: 1.4;
            font-weight: 700;
            text-align: center;
          }

          .pdf-title-second {
            width: 100%;
            color: variable.$primary-green-color;
            font-family: "Sora";
            font-size: 38px;
            letter-spacing: 1.2px;
            line-height: 1.4;
            font-weight: 300;
            color: variable.$primary-green-color;
            text-align: center;
          }

          .pdf-donor {
            margin: 0;

            .pdf-donor-title {
              width: 100%;
              color: #004332;
              font-family: "Sora";
              font-size: 16px;
              font-weight: 700;
              text-align: center;
              margin-bottom: 0px !important; 

            }

            .pdf-description{
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 14px;
              font-weight: 400;
              text-align: center;

              margin: 0px !important; 
            }
            .pdf-donor-title-sub {
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 10px;
              font-weight: 700;
              text-align: center;
              margin-top: 10px;
              margin-bottom: 0px;
            }

            .pdf-donor-fullname {
              width: 100%;
              color: variable.$chartreuse-color;
              font-family: "Sora";
              font-size: 28px;
              font-weight: 700;
              letter-spacing: 0px;
              text-align: center;
              word-wrap: break-word;
              margin: 0px !important;
              line-height: 1.4;
            }

            .pdf-donor-reason {
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 16px;
              font-weight: 300;
              letter-spacing: 0px;
              text-align: center;
              word-wrap: break-word;
              margin: 0;
              padding-bottom: 10px;
              line-height: 1.4;
            }

            .donation-serial{
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 14px;
              font-weight: 400;
              text-align: center;
            }
            .donation-date{
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 10px;
              font-weight: 400;
              text-align: center;
            }
          }

          .pdf-donor-area {
            margin: 0;
            min-height: 142px;

            .pdf-area-amount {
              margin: 0 0 5.92px 0;
              width: 100%;
              color: variable.$chartreuse-color;
              font-family: "Sora";
              font-size: 20px;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;

              sup {
                vertical-align: middle;
                font-size: 12px;
              }
            }

            .pdf-area-title {
              margin: 0 0 5.92px 0;
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;
            }

            .pdf-area-title-second {
              margin: 0 auto 32.87px auto;
              width: 80%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 14px;
              line-height: 1.5;
              font-weight: 400;
              letter-spacing: 0px;
              text-align: center;
            }
          }

          .pdf-co2 {
            margin: 0;
            min-height: 132px;
            sup {
              vertical-align: bottom;
              font-size: 10px;
            }

            .pdf-co2-title {
              margin: 0 0 5.92px 0;
              width: 100%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;
              sup {
                vertical-align: bottom;
                font-size: 10px;
              }
            }

            .pdf-co2-amount {
              margin: 0 0 5.93px 0;
              width: 100%;
              color: variable.$chartreuse-color;
              font-family: "Sora";
              font-size: 20px;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;
            }

            .pdf-co2-title-second {
              margin: 0 auto 32.87px auto;
              width: 85%;
              color: variable.$default-color-text;
              font-family: "Sora";
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              text-align: center;
            }

             
          }
        }
      }

      .pdf-footer {
        position: absolute;
        width: 100%;
        height: 115px;
        left: 0px;
        top: 0px;
        bottom: 0;
        margin-top: calc(100% - 75px);

        .pdf-footer-logo {
          margin: 0;
          height: 140px;
          position: absolute;
          bottom: 5px;
          left: 0;
          img {
            margin: 35px 0 auto 0;
            text-align: left;
            width: 104px;
          }
        }
        .sign-person-name {
          width: 100%;
          color: variable.$default-color-text;
          font-size: 10px;
          font-family: variable.$font-name-Sora;
          letter-spacing: 0.56px;
          line-height: 1.2;
          font-weight: 700;
          font-style: normal;
          text-align: left;
        }
        .sign-person-detail {
          margin: 0;
          width: 115px;
          color: variable.$default-color-text;
          font-size: 9px;
          font-family: variable.$font-name-Sora;
          letter-spacing: 0;
          line-height: 1.2;
          font-weight: 300;
          font-style: normal;
          text-align: left;
        } 
      }
    }
    .wi-logo-section{
      margin-left: 5px;
    }
    .campaign-logo-section{
      margin-left: 10px;
    }
    .qr-section{
      float: right;
    }
    .center-image{
      width: 150px !important;
      height: 103px !important;
      margin-top: -50px !important;
    }
    .right-image{
      width: 150px !important;
      height: 103px !important;
      margin-top: -50px !important;
      .orientation-image-container{
        align-self: flex-end !important;
        height: 100%;
      }
    }
    .left-image{
      width: 150px !important;
      height: 103px !important;
      margin-top: -50px !important;
             
      .orientation-image-container{
        align-self: flex-start !important;
        height: 100%;
      }
    }
  }
}
