@use '../../utils/variable.scss' as variable;

.import-page {
  width: 100%;

  .t-bold {
    font-weight: 700;
  }

  .import-header {
    background: variable.$second-green-color;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 40%;
    min-width: 540px;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 10px 10px;
    background-image: url(../../images/white-forest.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: 170px;
  }

  .import-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .impoty-btn {
      width: 40%;
      min-width: 540px;
      padding: 0px 20px;
      border: 1px solid variable.$second-green-color;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .col-12 {
        padding: 5px 10px;
      }

      .input-upload {
        display: none;
      }

      .input-item {
        padding-top: 5px;
        text-align: left;

        .p-selectbutton {
          .p-button {
            min-width: 100px;
            width: calc(100% / 3);
            border-radius: 30px;
          }
        }

        label {
          display: block;
          font-size: 12px;
          text-transform: uppercase;
          color: variable.$light-slate-gray;
          position: relative;
          padding-right: 8px;
          padding-bottom: 5px;

          .required-label {
            color: variable.$red-error;
          }
          .lowercase-label{
            text-transform: lowercase;
          }
        }

        input {
          width: 100%;
          border-radius: 16px;
          border: 0.5px solid;
          height: 40px;
          padding-left: 15px;

          &::placeholder {
            font-size: 12px;
          }
        }

        .inputNumber {
          width: 100%;
          border-radius: 16px;
          height: 40px;
          &::placeholder {
            font-size: 12px;
          }
        }

        .error {
          position: relative;
          color: variable.$red-error;
          letter-spacing: 1px;
          text-transform: uppercase;
          line-height: 1.3;
          font-size: 12px;
          font-weight: 400;
          padding: 10px 0px 0px 24px;

          &::before {
              display: block;
              content: url(../../images/errorIcon.svg);
              position: absolute;
              left: 0px;
              top: 8px;
              width: 18px;
              height: 18px;
          }
        }

        .p-campaign-dropdown {
          width: 100%;
          height: 48px;
          box-shadow: none;
          border-radius: 16px;
          position: relative;

          .p-dropdown-label {
            display: flex;
            align-items: center;
            color: variable.$second-green-color;
          }

          &:focus-visible {
            box-shadow: none;
            outline: none;
            border: 1px solid variable.$second-green-color !important;
          }

          .p-dropdown-trigger {
            transition: 0.16s ease-in-out all;

            .p-dropdown-trigger-icon {
              transform: rotate(0deg);
              transition: 0.16s ease-in-out all;
              color: variable.$second-green-color;
            }
          }

          .p-campaign-dropdown-panel {
            border-radius: 0 0 16px 16px;
            box-shadow: none !important;
            // transform-origin: center top !important;
            // top: 33px !important;

            .p-dropdown-items-wrapper {
              border: 1px solid variable.$second-green-color;
              border-radius: 6px;
              overflow-y: auto;
              max-height: 210px;

              .p-dropdown-item {
                // font-weight: 600;
                padding: 5px 16px;
                font-size: 14px;
                color: variable.$second-green-color;
                text-transform: none;
                cursor: pointer;
              }

              .p-dropdown-item.p-highlight {
                background: variable.$background-color-white-gray;
                color: variable.$primary-green-color !important;
              }
            }
          }
        }

        .p-campaign-dropdown:not(.p-disabled):hover,
        .p-campaign-dropdown:not(.p-disabled).p-focus {
          box-shadow: none;
          outline: none;
          border: 1px solid variable.$second-green-color !important;
        }

        .p-campaign-dropdown:not(.p-disabled).p-focus {
          .p-dropdown-trigger {
            transition: 0.16s ease-in-out all;

            .p-dropdown-trigger-icon {
              transform: rotate(180deg);
              transition: 0.16s ease-in-out all;
            }
          }
        }

        .p-campaign-dropdown-panel {
          // left: -1px !important;
          // width: calc(100% + 2px) !important;

          ::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .image-item {
        padding-top: 30px;
        img {
          width: 50%;
          height: 100%;
          margin: 0;
        }
      }

      .upload-file {
        padding-top: 20px;
        .label-upload-csv {
          width: 160px;
          padding: 0.5rem;
          font-size: 0.9375rem;
          font-weight: 600;
          font-style: normal;
          background-color: variable.$second-green-color;
          color: variable.$text-color-white;
          border-radius: 16px;
          cursor: pointer;
        }

        .name-file {
          padding-left: 20px;
        }
      }

      .upload-image {
        padding-top: 30px;
        display: flex;
        align-items: center;
        .label-upload-csv {
          background-color: variable.$second-green-color;
          padding: 7px 0px;
          border-radius: 16px;
          color: variable.$text-color-white;
          width: 160px;
        }

        .name-file {
          padding-left: 20px;
        }
      }

      .note-message {
        padding: 0;
        margin: 0.25rem 1rem;

        p {
          padding: 0;
          margin: 0;
          color: variable.$red-color;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .btn-import {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .btn-submit {
          padding: 0.5rem 1.5rem;
          margin: 0.5rem 1.5rem;
          background-color: variable.$primary-green-color;
          min-width: 150px;
          border: none;
          outline: none;

          &:hover {
            background-color: variable.$second-yellow-color;
          }
        }
      }
    }
  }

  .p-selectbutton {
    height: 48px;
    left: 0px;
    top: 0px;
    border: 1px solid variable.$primary-green-color;
    box-sizing: border-box;
    border-radius: 30px;
    position: relative;
    padding: 0 3px;
    

    .p-button {
      padding: 12px 20px;
      height: 40px;
      top: 3px;
      border: none;
      border-radius: 30px;
      animation: deselect_btn 60ms ease-in 1 reverse both;

      .p-button-label {
        font-size: 18px;
        font-family: Lato; 
        font-style: normal;
        color: variable.$primary-green-color;
      }

      &:focus {
        box-shadow: none;
      }

      &.p-highlight {
        border-color: variable.$second-green-color !important;
        background-color: variable.$second-yellow-color !important;
        border: 1px solid variable.$second-yellow-color !important;
        border-radius: 30px;
        animation: select_btn 220ms ease-out 1 normal both;
        background-image: linear-gradient(90deg, variable.$second-yellow-color, variable.$second-yellow-color);
        background-size: 0;
        background-repeat: no-repeat;

        &:hover {
          border-color: variable.$second-yellow-color;
          background-color: variable.$second-yellow-color;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .import-page .import-container .impoty-btn {
    width: 80%;
  }
}

@media screen and (max-width: 760px) {
  .import-page .import-container .impoty-btn {
    width: 90%;
    min-width: 300px;
  }
}


@keyframes select_btn {
	0% {
    transform: scale(0.2);
	}

	to {
    transform: scale(1);
	}
}


@keyframes deselect_btn {
	0% {
		background-size: 0 20%;
	}

	50% {
		background-size: 20% 50%;
	}

	to {
		background-size: 50% 100%;
	}
}

.import-container-extra {
  .p-inputtext {
    font-size: 14px;
  }
  .p-selectbutton {
    // height: 40px!important;
    .p-button-label {
      font-size: 15px!important;
    }
  }

  .p-campaign-dropdown {
    height: 40px!important;

    // .p-campaign-dropdown-panel {
    //   top: 25px!important
    // }
  }
}
