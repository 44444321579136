@use "../../../utils/variable.scss" as variable;

.people-manager {
  .logo-image {
    border-radius: 50% !important;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;
    height: 72px;
    width: 72px;
    min-width: 72px;
    box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
  }

  .wi-html-content {
    p {
      margin-bottom: 0;
    }
  }
}
